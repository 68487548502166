import { graphql } from "react-apollo";
import DOMAIN_BY_ROOT from "../graphql/DomainByRoot.graphql";
import map from "@gqlapp/base/utils/map";
/**
 * withLinkCreate
 * @param Component
 */ export const withDomainByRoot = graphql(DOMAIN_BY_ROOT, {
    props: (param)=>{
        let { mutate  } = param;
        return {
            domainByRoot: async (variables)=>{
                return map(map(await mutate({
                    variables
                }), (param)=>{
                    let { data  } = param;
                    return data;
                }), (param)=>{
                    let { res  } = param;
                    return res;
                });
            }
        };
    }
});
export default withDomainByRoot;
