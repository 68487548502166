import saveStatus from "@gqlapp/base/utils/saveStatus";
/**
 *
 * @param action
 * @param errorMsg
 */ const onAction = (param)=>{
    let { action , errorMsg  } = param;
    return async (input)=>{
        let values = await action(input);
        // @ts-ignore
        return saveStatus({
            values,
            errorMsg
        });
    };
};
export default onAction;
