import { graphql } from "react-apollo";
import loading from "@gqlapp/base/utils/loading";
import LINKS_DELETE from "../graphql/linksDelete.graphql";
/**
 * withLinksDeleting
 * @param Component
 */ export const withLinksDeleting = (Component)=>graphql(LINKS_DELETE, {
        props: (param)=>{
            let { mutate , ownProps: { loadbar  }  } = param;
            return {
                linksDelete: async (variables)=>loading(loadbar, async ()=>mutate({
                            variables
                        }))
            };
        }
    })(Component);
export default withLinksDeleting;
