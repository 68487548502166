import { Taxonomy } from "./Taxonomy";
import Entities from "@gqlapp/core-common/Entities";
import entitiesMore from "./entitiesMore";
export class Taxonomies extends Entities {
    set fetchMore(value) {
        this._fetchMore = value;
    }
    get fetchMore() {
        return entitiesMore(this._fetchMore);
    }
    set protected(value) {
        this._protected = value;
    }
    get protected() {
        return this._protected;
    }
    /**
   * get pager
   */ get variables() {
        return {
            pageSize: this.pageSize,
            orderBy: this.orderBy,
            direction: this.direction,
            page: this.page,
            filter: this.filter,
            protected: this.protected
        };
    }
    /**
   * instanse
   */ static instanse(props) {
        return new this(props).save([]);
    }
    /**
   * options
   * @param $vid
   * @param level
   * @param options
   */ options($vid) {
        let level = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
        const e = this.entities.filter(($tax)=>$tax.hierarchy === $vid || !$vid);
        let item = this.listToTree(e, options);
        return this.options_render(item, 0);
    }
    /**
   * load
   * @param $vid
   * @param options
   */ load($vid) {
        let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        return this.entities.filter(($tax)=>$tax.hierarchy === $vid || !$vid);
    }
    /**
   * Update entities
   * @param entities
   * @param reset
   * @returns {this}
   */ update(entities) {
        let reset = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        if (reset) {
            this.entities = [];
        }
        if (!entities) {
            return this;
        }
        const { edges , count  } = entities;
        this.count = count;
        this.save(edges);
        return this;
    }
    /**
   * add more entities
   * @param entities
   * @returns {this}
   */ add(entities) {
        if (!entities) {
            return this;
        }
        const { edges , count  } = entities;
        this.count = count;
        edges.map((node)=>{
            const $entity = new Taxonomy(null);
            $entity.save(node);
            this.addEntity($entity);
        });
        return this;
    }
    /**
   * save
   * @param entities
   */ save(entities) {
        /**
     * Check exists entity
     */ if (!entities) {
            return this;
        }
        /**
     * Map entities
     */ const $entities = [];
        entities.map((node)=>{
            const $entity = new Taxonomy();
            $entity.save(node);
            $entities.push($entity);
        });
        /**
     * Set entities
     */ this.entities = $entities;
        return this;
    }
    /**
   *
   * @param $options
   * @param $level
   * @param $entities
   * @private
   */ options_render($options) {
        let $level = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, $entities = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
        $options.map((opt)=>{
            let $name = "";
            if ($level > 0) {
                for(let i = 0; i < $level; i++){
                    $name += "--";
                }
            }
            $name = "".concat($name, " ").concat(opt.name);
            $entities.push({
                value: opt.id,
                label: $name.trim()
            });
            if (opt.children && opt.children.length) {
                $entities = this.options_render(opt.children, $level + 1, $entities);
            }
        });
        return $entities;
    }
    /**
   *
   * @param objectArray
   * @private
   */ groupBy(objectArray) {
        return objectArray.reduce((acc, obj)=>{
            const key = obj.vid;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }
    treeBy() {
        let tree = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], terms = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    }
    /**
   * Build true
   * @param data
   * @param options
   */ listToTree(data) {
        let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        options = options || {};
        const ID_KEY = options.idKey || "id";
        const PARENT_KEY = options.parentKey || "parent_id";
        const CHILDREN_KEY = options.childrenKey || "children";
        const tree = [], childrenOf = {};
        let item, id, parentId;
        for(let i = 0, length = data.length; i < length; i++){
            item = data[i];
            id = item[ID_KEY];
            parentId = item[PARENT_KEY] || 0;
            // every item may have children
            childrenOf[id] = childrenOf[id] || [];
            // init its children
            item[CHILDREN_KEY] = childrenOf[id];
            if (parentId != 0) {
                // init its parent's children object
                childrenOf[parentId] = childrenOf[parentId] || [];
                // push it into its parent's children object
                childrenOf[parentId].push(item);
            } else {
                tree.push(item);
            }
        }
        return tree;
    }
    constructor(props = null){
        super(props);
        this._fetchMore = null;
        this.subscribeToMore = null;
        this.updateQuery = null;
        this._protected = false;
        this.protected = !!(props === null || props === void 0 ? void 0 : props.protected);
    }
}
export default Taxonomies;
