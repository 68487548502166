import React from "react";
import withProps from "recompose/withProps";
let timeout = null;
export const withResizeProps = withProps((param)=>{
    let {} = param;
    const resize = ()=>{
        let elementClass;
        try {
            elementClass = $$("#root .view-main").classList;
        } catch (e) {}
        clearTimeout(timeout);
        timeout = setTimeout(()=>{
            try {
                elementClass.remove("auto");
                let mainHeight = $$("#root .view-main").offsetHeight;
                let pageHeight = $$("#root .view-main > .page").offsetHeight;
            // error({ mainHeight, pageHeight, y: (mainHeight - pageHeight)  })
            //   if (mainHeight > pageHeight) {
            //       // elementClass.add('auto')
            //   }
            } catch (e) {
            // console.warn(e)
            }
        }, 100);
    };
    try {
        resize();
    } catch (e) {}
    try {
        window.visualViewport.addEventListener("resize", ()=>{
            resize();
        });
    } catch (e1) {}
});
export default withResizeProps;
