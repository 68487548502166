import { graphql } from "react-apollo";
import mapInput from "@gqlapp/base/utils/mapInput";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import USERS_QUERY from "@xem/user-common/graphql/UsersQuery.graphql";
const withUsers = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), withProps((param)=>{
            let { users: { variables  }  } = param;
            return {
                ...variables
            };
        }), graphql(USERS_QUERY, {
            options: (param)=>{
                let { page , pageSize , direction , filter , fetchPolicy  } = param;
                return {
                    fetchPolicy: fetchPolicy || null,
                    variables: {
                        page,
                        pageSize,
                        direction,
                        filter,
                        fetchPolicy
                    }
                };
            },
            props (param) {
                let { data: { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  } , ownProps: { users , entitiesReset  }  } = param;
                users.refetch = refetch;
                users.fetchMore = fetchMore;
                users.subscribeToMore = subscribeToMore;
                users.updateQuery = updateQuery;
                if (!loading && res) {
                    users.update(res || {}, !!entitiesReset);
                }
                return {
                    loading,
                    users,
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
export default withUsers;
