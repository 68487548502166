// @ts-ignore
import loading from "@gqlapp/base/utils/loading";
/**
 *
 * @param charts
 * @param fetchMore
 * @param loadbar
 */ export const statsChartMore = (charts, fetchMore, param)=>{
    let { loadbar  } = param;
    return async (variables, bar)=>new Promise(async (resolve, reject)=>loading(bar || loadbar, async ()=>{
                try {
                    await fetchMore({
                        variables,
                        updateQuery: async (_, param)=>{
                            let { fetchMoreResult: { charts: res  }  } = param;
                            try {
                                charts.update(res);
                            } catch (e) {
                                error(e);
                            }
                            resolve(res);
                        }
                    });
                } catch (e) {
                    error(e);
                    reject({});
                }
            }));
};
export default statsChartMore;
