import TAXONOMY_TAGS from "../graphql/TaxonomyTags.graphql";
import removeTypename from "@gqlapp/base/utils/removeTypename";
/**
 * searchTags
 * @param t
 * @param subscribeToMore
 * @returns {function(*=): Promise<any>}
 */ export const searchTags = (subscribeToMore)=>(param)=>{
        let { data , keys  } = param;
        return new Promise(async (resolve)=>{
            data = data || [];
            try {
                await subscribeToMore({
                    document: TAXONOMY_TAGS,
                    variables: {
                        filter: {
                            search: {
                                keyword: keys.trim()
                            }
                        }
                    },
                    updateQuery: async (_, param)=>{
                        let { subscriptionData: { data: contents  }  } = param;
                        try {
                            const { tags: { edges  }  } = removeTypename(contents);
                            if (edges.length) {
                                edges.map((param)=>{
                                    let { name: label , id: value  } = param;
                                    return data.push({
                                        label,
                                        value
                                    });
                                });
                            }
                            resolve(data);
                        } catch (e) {
                            resolve(data);
                        }
                    }
                });
            } catch (e) {
                resolve(data);
            }
        });
    };
export default searchTags;
