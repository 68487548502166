import CustomPage from "./CustomPage";
import Entities from "@gqlapp/core-common/Entities";
import more from "@xem/entity/classes/fetchMore";
export class CustomPages extends Entities {
    set fetchMore(value) {
        this._fetchMore = value;
    }
    get fetchMore() {
        return more(this._fetchMore);
    }
    get filter() {
        if (this.ownDomain) {
            this._filter = {
                ...this._filter,
                ownDomain: this.ownDomain
            };
        }
        if (this.notOwnDomain) {
            this._filter = {
                ...this._filter,
                notOwnDomain: this.notOwnDomain
            };
        }
        return this._filter || null;
    }
    set filter(value) {
        this._filter = value;
    }
    /**
   * instanse
   */ static instanse() {
        let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        return new this(props);
    }
    constructor(props = {}){
        super(props);
        this._fetchMore = null;
        this.ownDomain = false;
        this.notOwnDomain = false;
        this.interface = CustomPage;
    }
}
export default CustomPages;
