"use strict";
module.exports = {
    "en": {
        "title": "We are sorry the page you requested cannot be found",
        "pageNotFound": "We are sorry the page you requested cannot be found",
        "url": "The requested URL {{value}} was not found on this server.",
        "back": "Go Home",
        "description": "The URL may be misspelled or the page you're<br />looking for is no longer available"
    },
    "vi": {
        "title": "Ch\xfang t\xf4i xin lỗi, kh\xf4ng thể t\xecm thấy trang bạn y\xeau cầu.",
        "pageNotFound": "Ch\xfang t\xf4i xin lỗi, kh\xf4ng thể t\xecm thấy trang bạn y\xeau cầu.",
        "url": "Kh\xf4ng t\xecm thấy URL được y\xeau cầu {{value}} tr\xean m\xe1y chủ n\xe0y.",
        "back": "Quay lại trang chủ",
        "description": "URL c\xf3 thể sai ch\xednh tả hoặc<br />trang bạn đang t\xecm kiếm kh\xf4ng tồn tại."
    }
};
