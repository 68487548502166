import saveStatus from "@gqlapp/base/utils/saveStatus";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Link from "@xem/link-common/classes/Link";
import withLink from "@xem/link-common/classes/withLink";
import withTaxonomies from "@xem/taxonomy-common/classes/withTaxonomies";
import Taxonomies from "@xem/taxonomy-common/classes/Taxonomies";
import preloadDomain from "@xem/domain-common/classes/preloadDomain";
import withLinkUpdate from "@xem/link-common/classes/withLinkUpdate";
import withLinkUpload from "@xem/link-common/classes/withLinkUpload";
import Square from "@xem/icon/images/shape/Square";
import Horizontal from "@xem/icon/images/shape/Horizontal";
import Vertical from "@xem/icon/images/shape/Vertical";
import { getPreview } from "@xem/look/Cropping/Base/Utils";
import searchTags from "@xem/taxonomy-common/classes/searchTags";
import { domainCallback } from "@xem/domain-client-react/helpers/popup/Search/Utils";
/**
 * msgCallback
 * @param mes
 */ const msgCallback = (mes)=>"".concat(mes).replace(/^\%type\:/i, "link:").t();
/**
 *
 * @param Save
 * @param entity
 * @param errorMsg
 */ export const onUpdate = (Save, param)=>{
    let { entity , errorMsg  } = param;
    return async (param)=>{
        let { id , ...input } = param;
        id = id || (entity === null || entity === void 0 ? void 0 : entity.id);
        let values;
        if (!id) return;
        values = await Save({
            input,
            id
        });
        // @ts-ignore
        return saveStatus({
            values,
            msgCallback,
            errorMsg: errorMsg || "link:update.errorMsg".t()
        });
    };
};
/**
 * onDelete
 * @param Save
 * @param errorMsg
 */ export const onDelete = function(Save) {
    let { errorMsg  } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return async (input)=>{
        let values = await Save(input);
        // @ts-ignore
        return saveStatus({
            values,
            msgCallback,
            errorMsg: errorMsg || "delete.errorMsg".t()
        });
    };
};
/**
 *
 * @param Save
 * @param refetch
 * @param errorMsg
 */ export const onCreate = function(Save) {
    let { refetch , errorMsg  } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return async (input)=>{
        let values = await Save(input);
        try {
            refetch();
        } catch (e) {}
        // @ts-ignore
        return saveStatus({
            values,
            msgCallback,
            errorMsg: errorMsg || "link:create.errorMsg".t()
        });
    };
};
/**
 *
 * @param Save
 */ export const onUpload = (Save, param)=>{
    let {} = param;
    return async (input)=>{
        const values = await Save(input);
        // @ts-ignore
        return saveStatus({
            values,
            msgCallback,
            errorMsg: "link:upload.errorMsg".t()
        });
    };
};
/**
 * View
 * @param t
 * @returns {function({file: *}): Promise<{file: *, url: *}>}
 */ export const onPreview = (param)=>{
    let {} = param;
    return async (param)=>{
        let { file  } = param;
        let url = file.preview;
        return {
            url,
            file
        };
    };
};
export const modalPreload = compose(withProps({
    entity: Link.instanse()
}), withLink((param)=>{
    let { id , entity , isOpen  } = param;
    return {
        fetchPolicy: "no-cache",
        skip: !isOpen,
        link: entity,
        path: id ? "/node/".concat(id) : null
    };
}), withProps((param)=>{
    let { refetch , entity  } = param;
    return {
        refetch: ()=>{
            try {
                entity.refetch();
            } catch (e) {}
            try {
                refetch();
            } catch (e1) {}
        }
    };
}));
export const preloadModalWithTaxonomy = compose(withTaxonomies((param)=>{
    let { isOpen  } = param;
    return {
        taxonomies: Taxonomies.instanse({
            protected: true
        }),
        skip: !isOpen
    };
}), modalPreload, withProps((param)=>{
    let { refetch , taxonomies  } = param;
    return {
        refetch: ()=>{
            try {
                taxonomies.refetch();
            } catch (e) {}
            try {
                refetch();
            } catch (e1) {}
        }
    };
}));
export const preloadModalWithDomain = compose(preloadDomain, modalPreload, withProps((param)=>{
    let { entity , refetch , domains  } = param;
    return {
        refetch: ()=>{
            try {
                domains.refetch();
            } catch (e) {}
            try {
                refetch();
            } catch (e1) {}
            try {
                entity.refetch();
            } catch (e2) {}
        }
    };
}));
export const modalWithDomain = withProps((props)=>{
    let { setFieldValue , values: { domain  }  } = props;
    function onChange(value, domains) {
        let domain = domains.filter((param)=>{
            let { value: id  } = param;
            return id == value;
        }).shift();
        setFieldValue("domain", domain);
    }
    function openDomainPopup() {
        domainCallback(props)({
            domain: domain === null || domain === void 0 ? void 0 : domain.value,
            onChange
        });
    }
    return {
        openDomainPopup
    };
});
export const withCustomOptions = withProps((param)=>{
    let { options  } = param;
    options = options || [
        {
            value: 0,
            icon: Square,
            size: "315:315",
            // @ts-ignore
            label: "image.size.square".t()
        },
        {
            value: 1,
            icon: Horizontal,
            size: "562.5:294",
            // @ts-ignore
            label: "image.size.horizontal".t()
        },
        {
            value: 2,
            icon: Vertical,
            size: "250:400",
            // @ts-ignore
            label: "image.size.vertical".t()
        }
    ];
    return {
        getSize: (size)=>{
            var ref;
            let preview = (ref = options[size]) === null || ref === void 0 ? void 0 : ref.size;
            return getPreview(preview);
        },
        options
    };
});
let timeout = null;
export const withPreload = withProps((param)=>{
    let { entity , onClose , popupRef , refetch  } = param;
    return {
        searchTags: searchTags(entity.subscribeToMore),
        refetch: ()=>{
            clearTimeout(timeout);
            timeout = setTimeout(()=>{
                refetch && refetch();
            }, 500);
            if (typeof onClose === "function") {
                onClose();
            } else {
                var ref;
                (ref = popupRef.current) === null || ref === void 0 ? void 0 : ref.close();
            }
        }
    };
});
/**
 * entityProps
 */ export const entityProps = compose(withLinkUpdate, withLinkUpload, withProps((param)=>{
    let { linkUpdate , uploadFile , ...props } = param;
    const propsEntity = {
        onUpdate: onUpdate(linkUpdate, props),
        onUpload: onUpload(uploadFile, props),
        onPreview: onPreview(props)
    };
    return {
        ...propsEntity,
        propsEntity: propsEntity
    };
}));
