import { graphql } from "react-apollo";
import TAXONOMIES_QUERY from "../graphql/TaxonomiesQuery.graphql";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import mapInput from "@gqlapp/base/utils/mapInput";
/**
 * withTaxonomies
 * @param input
 */ const withTaxonomies = (input)=>{
    return compose(withProps((props)=>mapInput(input, props)), graphql(TAXONOMIES_QUERY, {
        options: (param)=>{
            let { taxonomies , fetchPolicy , notifyOnNetworkStatusChange  } = param;
            let { protected: p , page , pageSize , orderBy , direction , filter  } = taxonomies === null || taxonomies === void 0 ? void 0 : taxonomies.variables;
            return {
                fetchPolicy: fetchPolicy || null,
                notifyOnNetworkStatusChange: !!notifyOnNetworkStatusChange,
                variables: {
                    protected: p,
                    page,
                    pageSize,
                    orderBy,
                    direction,
                    filter
                }
            };
        },
        props (param) {
            let { data: { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  } , ownProps: { taxonomies , entitiesReset  }  } = param;
            taxonomies.refetch = refetch;
            taxonomies.fetchMore = fetchMore;
            taxonomies.subscribeToMore = subscribeToMore;
            taxonomies.updateQuery = updateQuery;
            if (!loading && res) {
                taxonomies.update(res, !!entitiesReset);
            }
            return {
                loading,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
};
export default withTaxonomies;
