import { graphql } from "react-apollo";
import LOGOUT from "../graphql/Logout.graphql";
import map from "@gqlapp/base/utils/map";
import loading from "@gqlapp/base/utils/loading";
export default graphql(LOGOUT, {
    // @ts-ignore
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            logout: async ()=>loading(loadbar, async ()=>map(map(await mutate(), (param)=>{
                        let { data  } = param;
                        return data;
                    }), (param)=>{
                        let { logout: res  } = param;
                        return res;
                    }))
        };
    }
});
