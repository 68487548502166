class Product {
    /**
   * instanse
   */ static instanse() {
        return new this().save({});
    }
    /**
   * save
   */ save(param) {
        let { id , title , body , summary , attributes , attrObj  } = param;
        this.id = id;
        this.title = title;
        this.body = body;
        this.summary = summary;
        this.attributes = attributes;
        this.attrObj = attrObj || [];
        return this;
    }
    constructor(){
        this.attrObj = [];
    }
}
export { Product as default };
