import { graphql } from "react-apollo";
import USERS_UPDATE from "../graphql/UsersUpdate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";
const withUsersUpdate = (Component)=>graphql(USERS_UPDATE, {
        props: (param)=>{
            let { mutate , ownProps: { loadbar  }  } = param;
            return {
                usersUpdate: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                            variables
                        }), (param)=>{
                            let { data  } = param;
                            return data;
                        }), (param)=>{
                            let { res  } = param;
                            return res;
                        }))
            };
        }
    })(Component);
export default withUsersUpdate;
