import ClientModule from "@gqlapp/module-client-react";
import compose from "recompose/compose";
import Search from "./Search";
const popupComponents = [
    {
        component: compose(Search)
    }
];
export default new ClientModule({
    popupComponents
});
