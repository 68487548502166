import React from "react";
import withProps from "recompose/withProps";
import { map } from "../helpers/config";
import withFormFilter from "../helpers/Filter";
import ListCompose from "../helpers/ListCompose";
import userSearch from "@xem/user-common/classes/userSearch";
import userByEmail from "@xem/user-common/classes/userByEmail";
import userCount from "@xem/user-common/classes/userCount";
/**
import EntitiesView from "../components/EntitiesView";
/*/ import loadable from "next/dynamic";
/**
 * Load component
 * @type {React.ComponentType<Omit<Omit<{readonly sx?: *, readonly datasource?: *, readonly columns?: *, readonly values?: *, readonly links?: *, readonly RendersNone?: *, readonly pageProps?: *}, "sx">, "datasource" | "columns" | "RendersNone" | "config">>}
 */ const EntitiesView = loadable(()=>import("../components/EntitiesView").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("../components/EntitiesView")
            ]
    }
});
export default ListCompose({}, [
    withProps((param)=>{
        let { query  } = param;
        return {
            pageProps: {
                path: map({
                    path: "list"
                }),
                other: map({
                    path: "listpage",
                    page: ":page"
                }),
                query
            }
        };
    }),
    withProps((param)=>{
        let { entities , setFieldValue  } = param;
        return {
            refetch: ()=>{
                try {
                    entities.refetch();
                } catch (e) {}
                try {
                    setFieldValue("page", 1);
                } catch (e1) {}
            },
            userSearch: userSearch(entities.subscribeToMore),
            userByEmail: userByEmail(entities.subscribeToMore),
            userCount: userCount(entities.subscribeToMore)
        };
    }),
    withFormFilter
])(EntitiesView);
