// @ts-ignore
import isEmpty from "@gqlapp/base/utils/isEmpty";
export class Route {
    /**
   * instanse
   * Module cố định không thay đổi ko sử dụng biến get và set
   */ static instanse(data) {
        return new this().save(data || {});
    }
    save(_data) {
        let { metatags , hlinks , id , title , path , type_name , parent_id , hash , protected: p  } = _data || {};
        this.id = Number(id) || 0;
        this.hash = hash;
        this.parent_id = Number(parent_id) || 0;
        this.path = path;
        this.entity_type = type_name;
        this.type_name = type_name;
        this.metatags = metatags || [];
        this.hlinks = hlinks || [];
        this.protected = p;
        try {
            title = [
                ...metatags || []
            ].filter((param)=>{
                let { key  } = param;
                return key == "title" || key === "og:title";
            }).shift().value;
        } catch (e) {}
        if (!isEmpty(title)) {
            this.title = title;
        }
        return this;
    }
    get metaTitle() {
        return this.title;
    }
    /**
   *
   * @param value
   */ set protected(value) {
        this._protected = value;
    }
    get protected() {
        return this._protected || {};
    }
    constructor(){
        this.entity_type = "";
        this.title = "";
        this.path = "";
        this.type_name = "";
        this.id = 0;
        this.hash = "";
        this.parent_id = 0;
        this.metatags = [];
        this.hlinks = [];
        this._protected = {};
    }
}
export default Route;
