import Gen from "./Gen";
import Entities from "@gqlapp/core-common/Entities";
class Gens extends Entities {
    /**
   * instanse
   */ static instanse() {
        let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        return new this(props).save([]);
    }
    constructor(props){
        super(props);
        this.interface = Gen;
    }
}
export { Gens as default };
