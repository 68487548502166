import withProps from "recompose/withProps";
import { gradientParser } from "@gqlapp/base/colorful/utils/gradientParser";
export const QrValueMap = (values)=>{
    let gradient, { backgroundColor , image , paramValue , selectedIndex , eyeCustom , icon , frame , eyeShape , eyeInner , color , text  } = values || {};
    if (color === null || color === void 0 ? void 0 : color.includes("gradient")) {
        let type = (color === null || color === void 0 ? void 0 : color.includes("radial")) ? 1 : 0;
        let { orientation , colorStops  } = gradientParser(color);
        let values1 = colorStops.map((param)=>{
            let { value , left  } = param;
            left = left || 0;
            left = typeof left === "undefined" ? 0 : left;
            left = left * 100;
            return {
                left,
                value
            };
        });
        gradient = {
            values: values1,
            type,
            degreeStr: (orientation === null || orientation === void 0 ? void 0 : orientation.value) || 90,
            enabled: 1
        };
    }
    return {
        ...values,
        image: image || 0,
        paramValue: paramValue || [],
        selectedIndex: selectedIndex || 0,
        color: color || "#000000",
        eyeCustom: !!eyeCustom,
        icon: {
            ...icon,
            enabled: !!(icon && icon.src) ? 1 : 0,
            src: icon && icon.src ? icon.src : null,
            scale: 33,
            bg: 0,
            size: 0
        },
        gradient: gradient || {
            enabled: 0
        },
        frame: {
            text: text || "qr:text".t(),
            font: 0,
            style: 0,
            color: "#000000",
            icon: 0,
            iconColor: "#000",
            ...frame
        },
        backgroundColor: backgroundColor || "#ffffff",
        eyeShape: eyeShape || {
            top_left: {
                type: 0,
                color: "#000000"
            },
            bottom_left: {
                type: 0,
                color: "#000000"
            },
            top_right: {
                type: 0,
                color: "#000000"
            }
        },
        eyeInner: eyeInner || {
            top_left: {
                type: 0,
                color: "#000000"
            },
            bottom_left: {
                type: 0,
                color: "#000000"
            },
            top_right: {
                type: 0,
                color: "#000000"
            }
        }
    };
};
export default withProps((param)=>{
    let { values  } = param;
    return {
        values: QrValueMap(values)
    };
});
