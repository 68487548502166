import { compose, withProps } from "recompose";
import withLoading from "@xem/utils/withLoading";
import DOMAINS_QUERY from "@xem/domain-common/graphql/domainsQuery.graphql";
import Entities from "@xem/domain-common/classes/Domains";
import withDomains from "@xem/domain-common/classes/withDomains";
import withDomainDeleting from "@xem/domain-common/classes/withDomainDeleting";
import withDomainUpdate from "@xem/domain-common/classes/withDomainUpdate";
import withDomainBlock from "@xem/domain-common/classes/withDomainBlock";
import withDomainUnblock from "@xem/domain-common/classes/withDomainUnblock";
import withDomainPublic from "@xem/domain-common/classes/withDomainPublic";
import withDomainNonePublic from "@xem/domain-common/classes/withDomainNonePublic";
import withDomainVerify from "@xem/domain-common/classes/withDomainVerify";
import { getFilterByKey } from "./Filter";
import MediaTools from "./MediaTools";
import onAction from "./utils/onAction";
/**
 * Domains compose
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const DomainsCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        /**
		 * Get entities
		 * @param query
		 * @param cookie
		 * @param status
		 */ const getEntities = function getPath(param) {
            let { query , cookie , status ="active"  } = param;
            // let page = query?.page || 0, id = parseInt(query?.id);
            // let id = parseInt(query?.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CREATED",
                protected: true
            });
            let orderBy, direction;
            let filter = {};
            cookie && ({ orderBy , direction , status  } = getFilterByKey(cookie));
            !!status && Object.assign(filter, {
                status
            });
            !!orderBy && (entities.orderBy = orderBy);
            !!direction && (entities.direction = direction);
            return entities;
        };
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(MediaTools, withProps(input), withDomainDeleting, withDomainUpdate, withDomainBlock, withDomainUnblock, withDomainVerify, withDomainPublic, withDomainNonePublic, withProps((param)=>{
            let { domainBlock , domainPublic , domainSetNonePublic , domainUnblock , domainVerify , domainDelete , domainUpdate , query , cookie  } = param;
            var ref;
            return {
                onPublic: onAction(domainPublic, {
                    errorMsg: "domain:errorMsg.public".t()
                }),
                setNonePublic: onAction(domainSetNonePublic, {
                    errorMsg: "domain:errorMsg.not_public".t()
                }),
                onDelete: onAction(domainDelete, {
                    errorMsg: "domain:errorMsg.delete".t()
                }),
                onUpdate: onAction(domainUpdate, {
                    errorMsg: "domain:errorMsg.update".t()
                }),
                onBlock: onAction(domainBlock, {
                    errorMsg: "domain:errorMsg.block".t()
                }),
                onUnblock: onAction(domainUnblock, {
                    errorMsg: "domain:errorMsg.unblock".t()
                }),
                onVerify: onAction(domainVerify, {
                    errorMsg: "domain:errorMsg.verify".t()
                }),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), ...withPropsBefore || [], /**
			 *
			 */ withDomains((param)=>{
            let { entities  } = param;
            return {
                domains: entities,
                entitiesReset: true
            };
        }), /**
			 *
			 */ withProps((param)=>{
            let { entities , action  } = param;
            /**
				 *
				 */ const refetch = ()=>{
                entities && entities.refetch && entities.refetch();
            };
            action.callback = refetch;
            /**
				 *
				 */ return {
                refetch
            };
        }), ...withPropsInput || [], withLoading)(Route);
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { req , res , path , query , apolloClient  } = param;
            let ctx = {}, variables = {};
            let cookie = getCookie("domain_list_sort", {
                req,
                res
            });
            if (cookie) {
                Object.assign(ctx, {
                    cookie: {
                        filter: {
                            key: cookie
                        }
                    }
                });
            }
            let entities = getEntities({
                path,
                query,
                cookie
            });
            variables = entities.variables;
            /** Preload taxonomy **/ let result = await apolloClient.query({
                query: DOMAINS_QUERY,
                fetchPolicy: "no-cache",
                variables
            });
            /** set cache taxonomy **/ await apolloClient.writeQuery({
                query: DOMAINS_QUERY,
                data: result === null || result === void 0 ? void 0 : result.data,
                variables
            });
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
};
export default DomainsCompose;
