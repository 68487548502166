import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withUserLogout from "@xem/user-common/classes/withUserLogout";
export default compose(withUserLogout, withProps((param)=>{
    let { logout , refetchCurrentUser  } = param;
    return {
        logout: async ()=>{
            logout().then(()=>{
                window.location.reload();
            }).catch(errorCatch);
        }
    };
}));
