import axios from "axios";
export const defaults = {};
// @ts-ignore
export default ((pubsub)=>({
        Mutation: {
            apiSearch: async (obj, param, __)=>{
                let { type , keys , lang , country  } = param;
                return new Promise(async (resolve, reject)=>{
                    let options = {
                        params: {
                            type,
                            keys,
                            lang,
                            country
                        }
                    };
                    await axios.get("/api/scraper", options).then(resolve).catch(()=>reject([]));
                });
            }
        }
    }));
