import { graphql } from "react-apollo";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import statsDeviceMore from "./Stats/statsDeviceMore";
import STATS_DEVICE_QUERY from "../graphql/StatsDevices.graphql";
/**
 * withStatsDevice
 * @param input
 */ export const withStatsDevice = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), graphql(STATS_DEVICE_QUERY, {
            skip: (param)=>{
                let { skip  } = param;
                return !!skip;
            },
            options: (param)=>{
                let { id , deviceProps  } = param;
                return {
                    variables: {
                        id: id || null,
                        ...deviceProps
                    }
                };
            },
            props (param) {
                let { data: { loading , devices , error , fetchMore  } , ownProps , ownProps: { stats  }  } = param;
                if (!loading) {
                    // console.warn('#######', 'withStatsDevice')
                    stats.devices = devices;
                }
                return {
                    loading,
                    statsDeviceMore: statsDeviceMore(stats, fetchMore, ownProps),
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
export default withStatsDevice;
