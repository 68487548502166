import { graphql } from "react-apollo";
import LINKS_QUERY from "../graphql/linksQuery.graphql";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
/**
 * withLinks
 * @param input
 */ export const withLinks = (input)=>compose(withProps((props)=>mapInput(input, props)), graphql(LINKS_QUERY, {
        options: (param)=>{
            let { links , fetchPolicy , notifyOnNetworkStatusChange  } = param;
            let variables = links.variables;
            return {
                fetchPolicy: fetchPolicy || null,
                variables,
                notifyOnNetworkStatusChange: !!notifyOnNetworkStatusChange
            };
        },
        props (param) {
            let { ownProps: { links , entitiesReset  } , data: { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  }  } = param;
            links.refetch = refetch;
            links.fetchMore = fetchMore;
            links.subscribeToMore = subscribeToMore;
            links.updateQuery = updateQuery;
            // console.error({ links, loading, res, __SSR__, __TEST__ })
            if (!loading && res) {
                links.update(res, !!entitiesReset);
            }
            return {
                loading,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
export default withLinks;
