import saveStatus from "@gqlapp/base/utils/saveStatus";
/**
 * Delete multiple elements
 * @param Delete
 */ export const onDeletes = function(Delete) {
    let {} = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return async (input)=>{
        // debug({ input })
        let values = await Delete(input);
        // @ts-ignore
        return saveStatus({
            values,
            errorMsg: "webrisk:delete.errorMsg".t()
        });
    };
};
export default onDeletes;
