import React from "react";
import loadable from "next/dynamic";
import HomeCompose from "../helpers/HomeCompose";
const DashboardContainer = loadable(()=>import("./Dashboard").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Dashboard")
            ]
    }
});
export default HomeCompose()(DashboardContainer);
