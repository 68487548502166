import { graphql } from "react-apollo";
import USERS_DELETE from "../graphql/UsersDelete.graphql";
import map from "@gqlapp/base/utils/map";
import loading from "@gqlapp/base/utils/loading";
const withUsersDeleting = (Component)=>graphql(USERS_DELETE, {
        props: (param)=>{
            let { mutate , ownProps: { loadbar  }  } = param;
            return {
                usersDelete: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                            variables
                        }), (param)=>{
                            let { data  } = param;
                            return data;
                        }), (param)=>{
                            let { res  } = param;
                            return res;
                        }))
            };
        }
    })(Component);
export default withUsersDeleting;
