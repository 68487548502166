import { Nothing } from "purify-ts";
const defaultColor = {
    stroke: "var(--blue-200)",
    fill: "var(--blue-50)"
};
export const initialState = {
    nodes: {
        "0": {
            id: "0",
            position: {
                x: 50,
                y: 500
            },
            size: {
                width: 100,
                height: 100
            },
            color: {
                stroke: "var(--pink-400)",
                fill: "var(--pink-100)"
            }
        },
        "1": {
            id: "1",
            position: {
                x: 250,
                y: 300
            },
            size: {
                width: 100,
                height: 150
            },
            color: defaultColor
        },
        "2": {
            id: "2",
            position: {
                x: 250,
                y: 700
            },
            size: {
                width: 100,
                height: 120
            },
            color: defaultColor
        },
        "3": {
            id: "3",
            position: {
                x: 450,
                y: 100
            },
            size: {
                width: 100,
                height: 100
            },
            color: defaultColor
        }
    },
    root: {
        id: "0",
        nodes: [
            {
                id: "1",
                nodes: [
                    {
                        id: "3",
                        nodes: []
                    }
                ]
            },
            {
                id: "2",
                nodes: []
            }
        ]
    },
    selectedNode: Nothing
};
export const update = (doc, maybeId, changes)=>{
    return maybeId.caseOf({
        Nothing: ()=>doc,
        // @ts-ignore
        Just: (id)=>{
            const node = doc.nodes[id];
            if (node) {
                return {
                    ...doc,
                    nodes: {
                        ...doc.nodes,
                        [id]: {
                            ...node,
                            ...changes
                        }
                    }
                };
            }
            return doc;
        }
    });
};
