import loadingProcess from "@gqlapp/base/utils/loading/loading";
/**
 * Load
 * @param subscribeToMore
 * @param QUERY
 * @constructor
 */ export const Load = (subscribeToMore, QUERY)=>(variables)=>new Promise(async (resolve)=>{
            try {
                subscribeToMore({
                    document: QUERY,
                    variables: variables,
                    updateQuery: async (_, param)=>{
                        let { subscriptionData: { data: { res  }  }  } = param;
                        resolve(res);
                    }
                });
            } catch (e) {
                resolve({});
            }
        });
/**
 * QueryLoading
 * @param loadbar
 * @param subscribeToMore
 * @param QUERY
 * @constructor
 */ function QueryLoading(loadbar, subscribeToMore, QUERY) {
    return async (rest)=>{
        return await loadingProcess(loadbar, async ()=>Load(subscribeToMore, QUERY)(rest));
    };
}
export default QueryLoading;
