import withProps from "recompose/withProps";
let timeout = null;
const withLoading = withProps((props)=>{
    clearTimeout(timeout);
    timeout = setTimeout(()=>{
        var ref;
        if (!(props === null || props === void 0 ? void 0 : props.loading) && (props === null || props === void 0 ? void 0 : props.loadbar) && (props === null || props === void 0 ? void 0 : (ref = props.loadbar) === null || ref === void 0 ? void 0 : ref.done)) {
            var ref1;
            props === null || props === void 0 ? void 0 : (ref1 = props.loadbar) === null || ref1 === void 0 ? void 0 : ref1.done();
        }
    }, 100);
});
export default withLoading;
