import settings from "@xem/config";
import Action from "@xem/entity/classes/Action";
const user = {
    list: "/users.html",
    listpage: "/users/:page/page.html",
    forgot: "/forgot-password.html",
    reset: "/reset/:id/:timestamp/:hash.html",
    change: "/reset-password/:token.html",
    register: "/register.html",
    login: "/login.html",
    profile: "/profile.html",
    settings: "/settings.html"
};
export const map = (param)=>{
    let { id , path , hash , timestamp , token  } = param;
    return "".concat(user[path]).replace(/:id/, id).replace(/:timestamp/, timestamp || ":timestamp").replace(/:hash/, hash || ":hash").replace(/:token/, token || ":token");
};
const defaultConfig = {
    ...settings.route,
    runtimejs: true
};
export const userConfigLogined = {
    ...defaultConfig,
    action: Action.instanse(),
    redirectOnAnonymous: true,
    webType: "cpanel",
    mobile: {
        show: true
    },
    classNameOnPage: "theme-mobile",
    redirect: "/login.html"
};
export const userConfigAnonymous = {
    ...defaultConfig,
    redirectOnLoggedIn: true
};
