import withProps from "recompose/withProps";
import settings from "@xem/config/settings";
export const withRouteProps = withProps((param)=>{
    let { entity  } = param;
    let { uid , universal , origin , category , locked , type , advanced  } = entity || {};
    let { google , facebook  } = advanced || {};
    let googleUID = google === null || google === void 0 ? void 0 : google.uid;
    let googleTag = google === null || google === void 0 ? void 0 : google.tag;
    let facebookPixel = facebook === null || facebook === void 0 ? void 0 : facebook.pixel;
    let REGEX = /UA\-(\d){0,12}/i;
    if (!googleTag && googleUID && !"".concat(googleUID).match(REGEX)) {
        googleTag = googleUID;
    }
    let settingObj = {};
    [
        "uid",
        "ios",
        "android",
        "universal",
        "origin",
        "deeplink",
        "category",
        "locked",
        "advanced",
        "type"
    ].map((key)=>{
        if (entity[key]) {
            settingObj[key] = entity[key];
        }
    });
    settings.setJS("entity", settingObj);
    PLATFORM && settings.setJS("platform", PLATFORM);
    isIOS() && settings.setJS("ios", isIOS());
    isAndroid() && settings.setJS("android", isAndroid());
    googleUID && settings.setJS("ga", googleUID);
    googleTag && settings.setJS("gtag", googleTag);
    facebookPixel && settings.setJS("fb", facebookPixel);
    settings.addLibrary("jquery/jquery.min.js", {
        kind: "head",
        weight: -1000,
        crossOrigin: "anonymous",
        referrerPolicy: "no-referrer"
    });
    settings.addLibrary("static/TryvSettings.js", {
        kind: "head",
        weight: -1000
    });
    settings.addLibrary("static/Tryv.js", {
        kind: "head",
        weight: -99
    });
    settings.addLibrary("static/TryvInit.js", {
        kind: "head",
        weight: -98
    });
    settings.addLibrary("seo/seo.js", {
        weight: 0
    });
    if (googleTag) {
        settings.addLibrary("https://www.googletagmanager.com/gtm.js?id=".concat(googleTag), {
            withoutTime: 1,
            kind: "head",
            async: 1,
            weight: 3
        });
    }
    return {
        type,
        locked,
        category,
        uid,
        universal,
        origin,
        analytics: {
            uid: googleUID,
            pixel: facebookPixel
        }
    };
});
export default withRouteProps;
