import loadable from "next/dynamic";
import CustomPagesCompose from "../helpers/CustomPagesCompose";
import withProps from "recompose/withProps";
import onSave from "@xem/entity/classes/onSave";
import withCustomPageCreate from "@xem/custom-page-common/classes/withCustomPageCreate";
import withCustomPageUpdate from "@xem/custom-page-common/classes/withCustomPageUpdate";
import withPopupCreate from "../components/form/action/create";
import withPopupConfig from "../components/form/action/config";
import withRenderProcess from "@xem/entity/classes/withRenders";
import blocks from "../components/form";
const ListContainer = loadable(()=>import("../components/IntroPagesView").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("../components/IntroPagesView")
            ]
    }
});
export default CustomPagesCompose({}, [
    withCustomPageCreate,
    withCustomPageUpdate,
    blocks,
    withProps((param)=>{
        let { pageCreate , pageUpdate , entity , query  } = param;
        return {
            onSave: onSave(pageCreate, pageUpdate, {
                entity
            })
        };
    }),
    withPopupConfig,
    withPopupCreate,
    withRenderProcess
])(ListContainer);
