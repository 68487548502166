import { compose, withProps } from "recompose";
import withLoading from "@xem/utils/withLoading";
import { getFilterByKey } from "./Filter";
import Entities from "@xem/taxonomy-common/classes/Taxonomies";
import withTaxonomies from "@xem/taxonomy-common/classes/withTaxonomies";
import withTaxonomyDelete from "@xem/taxonomy-common/classes/withTaxonomyDelete";
import TAXONOMIES_QUERY from "@xem/taxonomy-common/graphql/TaxonomiesQuery.graphql";
import onDelete from "./utils/onDelete";
import MediaTools from "./MediaTools";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const ListCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        const getEntities = function getPath(param) {
            let { query , cookie  } = param;
            let page = (query === null || query === void 0 ? void 0 : query.page) || 0, id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CREATED",
                protected: true
            });
            let orderBy, direction;
            let filter = {};
            cookie && ({ orderBy , direction  } = getFilterByKey(cookie));
            !!orderBy && (entities.orderBy = orderBy);
            !!direction && (entities.direction = direction);
            entities.filter = filter;
            entities.page = parseInt("".concat(page));
            return entities;
        };
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(MediaTools, withProps(input), withTaxonomyDelete, withProps((param)=>{
            let { taxonomyDelete , query , cookie , ...props } = param;
            var ref;
            return {
                onDelete: onDelete(taxonomyDelete, props),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), ...withPropsBefore || [], withTaxonomies((param)=>{
            let { entities  } = param;
            return {
                taxonomies: entities,
                entitiesReset: true
            };
        }), ...withPropsInput || [], withLoading)(Route);
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { req , res , path , query , apolloClient  } = param;
            let ctx = {}, variables = {};
            let cookie = getCookie("taxonomy_list_sort", {
                req,
                res
            });
            if (cookie) {
                Object.assign(ctx, {
                    cookie: {
                        filter: {
                            key: cookie
                        }
                    }
                });
            }
            let entities = getEntities({
                path,
                query,
                cookie
            });
            variables = entities.variables;
            /** Preload taxonomy **/ let result = await apolloClient.query({
                query: TAXONOMIES_QUERY,
                fetchPolicy: "no-cache",
                variables
            });
            /** set cache taxonomy **/ await apolloClient.writeQuery({
                query: TAXONOMIES_QUERY,
                data: result === null || result === void 0 ? void 0 : result.data,
                variables
            });
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
};
export default ListCompose;
