import React from "react";
import loadable from "next/dynamic";
const YoutubeContainer = loadable(()=>import("./Youtube").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Youtube")
            ]
    }
});
export default YoutubeContainer;
