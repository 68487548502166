import USERS_COUNT from "../graphql/UsersCount.graphql";
/**
 * searchTags
 * @param subscribeToMore
 */ export const userCount = (subscribeToMore)=>(param)=>{
        let { filter  } = param;
        return new Promise(async (resolve)=>{
            try {
                await subscribeToMore({
                    document: USERS_COUNT,
                    variables: {
                        filter
                    },
                    updateQuery: async (_, param)=>{
                        let { subscriptionData: { data: { countUsers: { count  }  }  }  } = param;
                        resolve({
                            count
                        });
                    }
                });
            } catch (e) {
                resolve({});
            }
        });
    };
export default userCount;
