import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import { graphql } from "react-apollo";
import CUSTOM_PAGES_QUERY from "../graphql/CustomPagesQuery.graphql";
export const withCustomPages = (input)=>compose(withProps((props)=>mapInput(input, props)), withProps((param)=>{
        let { customPages: { variables  }  } = param;
        return {
            ...variables
        };
    }), graphql(CUSTOM_PAGES_QUERY, {
        options: (param)=>{
            let { fetchPolicy , page , pageSize , orderBy , direction , filter  } = param;
            return {
                variables: {
                    page,
                    pageSize,
                    orderBy,
                    direction,
                    filter
                },
                fetchPolicy: fetchPolicy || "cache-and-network"
            };
        },
        props (param) {
            let { ownProps: { customPages  } , data: { loading , res , updateQuery  } , data: { fetchMore , subscribeToMore , refetch , error  }  } = param;
            customPages.refetch = refetch;
            customPages.fetchMore = fetchMore;
            customPages.subscribeToMore = subscribeToMore;
            customPages.updateQuery = updateQuery;
            if (!loading && res) {
                customPages.update(res);
            }
            return {
                loading,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
export { CUSTOM_PAGES_QUERY };
export default withCustomPages;
