import saveStatus from "@gqlapp/base/utils/saveStatus";
/**
 * Update multiple elements
 * @param Save
 */ export const onUpdates = (Save, param)=>{
    let {} = param;
    return async (input)=>{
        let values = await Save(input);
        // @ts-ignore
        return saveStatus({
            values,
            errorMsg: "link:update.errorMsg".t()
        });
    };
};
export default onUpdates;
