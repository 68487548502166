import React from "react";
import loadable from "next/dynamic";
import ProductsCompose from "../helpers/ProductsCompose";
import withProps from "recompose/withProps";
import { map } from "../helpers/config";
import withFormFilter from "../helpers/Filter";
const ListContainer = loadable(()=>import("./List").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./List")
            ]
    }
});
export default ProductsCompose({}, [
    withProps((param)=>{
        let { query  } = param;
        return {
            pageProps: {
                path: map({
                    path: "list"
                }),
                other: map({
                    path: "listpage",
                    page: ":page"
                }),
                query
            }
        };
    }),
    withFormFilter,
    withProps((param)=>{
        let { entities , store  } = param;
        return {
            refetch: ()=>{
                try {
                    entities.refetch();
                } catch (e) {}
            }
        };
    })
])(ListContainer);
