import React from "react";
import loadable from "next/dynamic";
const CreateContainer = loadable(()=>import("./Edit").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Edit")
            ]
    }
});
export default CreateContainer;
