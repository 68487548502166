import { graphql } from "react-apollo";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import QR_TEXT_QUERY from "../graphql/QrTextQuery.graphql";
export const withQrText = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), graphql(QR_TEXT_QUERY, {
            options: (param)=>{
                let { textProps: input  } = param;
                return {
                    variables: {
                        input
                    }
                };
            },
            props (param) {
                let { data: { loading , text , refetch , error  }  } = param;
                return {
                    loading,
                    svgText: text || {
                        metrics: {}
                    },
                    qrRefetch: refetch,
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
export default withQrText;
