import { graphql } from "react-apollo";
import CUSTOM_PAGE_UPDATE from "../graphql/CustomPageUpdate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";
export const withCustomPageUpdate = graphql(CUSTOM_PAGE_UPDATE, {
    // @ts-ignore
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            pageUpdate: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                        variables
                    }), (param)=>{
                        let { data  } = param;
                        return data;
                    }), (param)=>{
                        let { res  } = param;
                        return res;
                    }))
        };
    }
});
export default withCustomPageUpdate;
