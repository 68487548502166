import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Domains from "./Domains";
import withDomains from "./withDomains";
import withDomainByRoot from "./withDomainByRoot";
export default compose(withProps((param)=>{
    let { domains  } = param;
    domains = Domains.instanse();
    domains.filter = {
        type: [
            "ns"
        ],
        get_default: true,
        public: true
    };
    return {
        domains
    };
}), withDomains((param)=>{
    let { isOpen  } = param;
    return {
        skip: !isOpen
    };
}), withDomainByRoot);
