import { graphql } from "react-apollo";
import DOMAIN_BLOCK from "../graphql/domainUpdate.graphql";
/**
 *
 * @param Component
 */ export const withDomainUnblock = graphql(DOMAIN_BLOCK, {
    props: (param)=>{
        let { mutate  } = param;
        return {
            domainUnblock: async (id)=>mutate({
                    variables: {
                        id,
                        input: {
                            status: "pending"
                        }
                    }
                })
        };
    }
});
export default withDomainUnblock;
