import compose from "recompose/compose";
import Button from "./Button";
import Divider from "./Divider";
import Form from "./Form";
import Html from "./Html";
import Social from "./Social";
// import Text from "./Text";
// import Avatar from "./Avatar";
// import Carousel from "./Carousel";
// import Countdown from "./Countdown";
//
// import Faq from "./Faq";
// import Maps from "./Maps";
// import MediaAndText from "./MediaAndText";
// import Messaging from "./Messaging";
// import Music from "./Music";
// import Page from "./Page";
// import Products from "./Products";
// import Social from "./Social";
// import Video from "./Video";
// import Vcard from "./Vcard";
export default compose(Button, Html, Divider, Form, Social);
