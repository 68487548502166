class Action {
    /**
   *
   */ static instanse() {
        return new this();
    }
    set callback(value) {
        this._callback = value;
    }
    get callback() {
        return this._callback;
    }
    set set(value) {
        this._callback = value;
    }
    get get() {
        return this._callback;
    }
    constructor(){
        this._callback = ()=>{};
    }
}
export default Action;
