import React from "react";
import { translate } from "@gqlapp/i18n-client-react";
import replace from "@gqlapp/base/utils/replace";
import Router from "next/router";
import i18next from "i18next";
import baseErrorCatch from "@gqlapp/base/errorCatch";
import { getItem, setItem } from "@gqlapp/core-common/clientStorage";
import isEmpty from "@gqlapp/base/utils/isEmpty";
function RootComponent(param) {
    let { children , t , ...props } = param;
    /**
   * historyPush
   * @param value
   */ try {
        // @ts-ignore
        historyPush = (value)=>{
            Router.replace(value).then(()=>{});
        };
    } catch (e) {}
    try {
        if ([
            ...props.history
        ].length > 1) {
            // @ts-ignore
            historyBack = function() {
                let backUrl = props.history.at(-2);
                backUrl = isEmpty(backUrl) ? "/" : backUrl;
                historyPush(backUrl);
            };
        }
    } catch (e1) {}
    try {
        // @ts-ignore
        msg = (value)=>{
            return (props === null || props === void 0 ? void 0 : props.alert) && (props === null || props === void 0 ? void 0 : props.alert(value));
        };
    } catch (e2) {}
    try {
        // @ts-ignore
        String.prototype.$$ = (e)=>{
            try {
                return document.querySelector(e);
            } catch (e1) {}
        };
    } catch (e3) {}
    try {
        // @ts-ignore
        errorCatch = baseErrorCatch(props);
    } catch (e4) {
        console.warn(e4);
    }
    t = __SERVER__ ? i18next.t : t;
    if (typeof t === "function") {
        // @ts-ignore
        String.prototype.t = function() {
            let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
            return t(this.toString(), props);
        };
        // @ts-ignore
        String.prototype.r = function() {
            let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
            try {
                return replace(this.toString(), props);
            } catch (e) {
                return this.toString();
            }
        };
        // @ts-ignore
        Array.prototype.t = function(value) {
            let props = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
            if (this.length === 2) {
                value = value || 0;
                let values = this;
                let val = values[value !== 1 ? 1 : 0];
                return "".concat(val).t().r(props);
            }
            return "";
        };
    }
    /**
   * Set location path
   */ let asPath;
    if (asPath = props === null || props === void 0 ? void 0 : props.asPath) {
        setItem("location", asPath);
    }
    /**
   * historyPush
   * @param value
   */ try {
        Object.assign(window.history, {
            previous: {
                href: props === null || props === void 0 ? void 0 : props.preurl
            }
        });
    } catch (e5) {}
    return children;
}
export default translate("common")(RootComponent);
