import { graphql } from "react-apollo";
import QUERY from "../graphql/CustomPageQuery.graphql";
import mapInput from "@gqlapp/base/utils/mapInput";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
const queryCustomPage = graphql(QUERY, {
    skip: (param)=>{
        let { skip , path  } = param;
        return skip || !path;
    },
    options: (param)=>{
        let { path , fetchPolicy  } = param;
        return {
            variables: {
                path
            },
            fetchPolicy: fetchPolicy || "cache-and-network"
        };
    },
    props (param) {
        let { data: { loading , res , refetch , subscribeToMore , error  } , ownProps: { customPage  }  } = param;
        !!customPage && (customPage.refetch = refetch);
        !!customPage && (customPage.subscribeToMore = subscribeToMore);
        !loading && res && customPage && customPage.save(res);
        return {
            loading,
            errors: error ? error.graphQLErrors : null
        };
    }
});
export { QUERY as CUSTOM_PAGE_QUERY };
export default ((input)=>compose(withProps((props)=>mapInput(input, props)), queryCustomPage));
