"use strict";
module.exports = {
    "en": {
        "products": {
            "title": "Product List"
        },
        "order": {
            "accessDenied": "You do not have permission to do this."
        },
        "form": {
            "btn": {
                "add": "Add product",
                "delete": "Delete product",
                "edit": "Edit product"
            },
            "actions": {
                "price": "Edit price",
                "warehouse": "Edit inventory",
                "sku": "Edit SKU",
                "delete": "Delete version",
                "cancel": "Cancel",
                "product": {
                    "errorNotity": "You need to create a store before creating products.",
                    "btnClose": "Close notification",
                    "btnConfirm": "Agree"
                }
            },
            "attributes": {
                "label": "Attribute",
                "btn": {
                    "add": "Add attribute",
                    "delete": "Delete attribute",
                    "edit": "Edit properties",
                    "change": "Edit"
                },
                "apply": "Apply",
                "applyVersion": "Apply to all versions",
                "version": {
                    "deleted": "Version has been deleted",
                    "undeleted": "Undo"
                },
                "price": "Selling price: <strong>{{value}}</strong>",
                "warehouse": "In stock: <strong>{{value}}</strong>"
            },
            "field": {
                "attributes": {
                    "label": "Attribute",
                    "name": "Attribute name",
                    "placeholder": "Enter attributes",
                    "selected": "Selected {{value}} version",
                    "all": "All versions",
                    "versions": "{{value}} versions",
                    "values": "Values"
                },
                "name": "Product name",
                "category": "Product category",
                "description": "Description"
            },
            "store": {
                "title": "Store name",
                "mail": "E-mail",
                "phone": "Phone number",
                "address": "Address"
            },
            "address": {
                "name": "Full name",
                "address": "Address",
                "address2": "Address 2",
                "city": "City",
                "state": "State - Province",
                "zipcode": "Area code",
                "country": "Country",
                "btn": {
                    "add": "Add address",
                    "delete": "Delete address",
                    "edit": "Edit address"
                }
            }
        },
        "notify": {
            "odd": {
                "title": "List of {{value}} products"
            }
        },
        "popup": {
            "store": {
                "info": "Store",
                "address": "Address"
            },
            "action": "Choose action",
            "warehouse": "Edit inventory",
            "price": "Edit price",
            "sku": "Edit sku",
            "info": "Product information",
            "delete": "Delete product",
            "attributes": "Attributes"
        }
    },
    "vi": {
        "text": "What are you waiting for to scan?",
        "colors": {
            "title": "Choose color style",
            "used": "Color used",
            "board": "Color board",
            "custom": "Custom color"
        },
        "Thiết kế": {
            "default": "Set QR as default",
            "save": "Save the design"
        },
        "edit": {
            "download": "Edit and download Qr Code",
            "label": "QR CODE Settings"
        },
        "gradient": {
            "type": {
                "label": "Choose gradient style",
                "linear": "Linear",
                "radial": "Radial"
            }
        },
        "toolbars": {
            "card": "Business card",
            "design": "Appearance",
            "frame": "QR frame",
            "data": "Data",
            "eye": "Pattern",
            "logo": "Logo",
            "color": "Color",
            "menu": "Menu"
        },
        "label": {
            "frame": "QR frame",
            "data": "Data",
            "eye": "Pattern",
            "logo": "Logo",
            "colors": "Colors",
            "menu": "Menu",
            "popup": {
                "font": "Font",
                "download": "Download Qr Code"
            }
        },
        "title": {
            "frame": "QR frame",
            "data": "Data",
            "eye": "Pattern",
            "logo": "Logo",
            "colors": "Colors",
            "menu": "Menu"
        },
        "color": "Color",
        "eye": "Pattern",
        "data": "Data",
        "frame": "QR frame",
        "eyes": {
            "top_left": "Pattern 1",
            "top_right": "Pattern 2",
            "bottom_left": "Pattern 3",
            "eyeShape": "Pattern Frame Type {{value}}",
            "eyeInner": "Pattern eye style {{value}}",
            "color": {
                "shape": "Frame",
                "inner": "Eye"
            }
        },
        "eyeShape": {
            "top_left": {
                "type": "Pattern"
            },
            "top_right": {
                "type": "Pattern"
            },
            "bottom_left": {
                "type": "Pattern"
            }
        },
        "eyeInner": {
            "top_left": {
                "type": "Eye"
            },
            "top_right": {
                "type": "Eye"
            },
            "bottom_left": {
                "type": "Eye"
            }
        }
    }
};
