import { compose, withProps } from "recompose";
import withLoading from "@xem/utils/withLoading";
import WEBRISKS_QUERY from "@xem/webrisk-common/graphql/webrisksQuery.graphql";
import Entities from "@xem/webrisk-common/classes/Webrisks";
// @ts-ignore
import withWebrisks from "@xem/webrisk-common/classes/withWebrisks";
// @ts-ignore
import withWebrisksDeleting from "@xem/webrisk-common/classes/withWebrisksDeleting";
import onDeletes from "./utils/onDeletes";
import { getFilterByKey } from "./Filter";
import MediaTools from "./MediaTools";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const WebrisksCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        const getEntities = function getPath(param) {
            let { query , cookie , status ="active"  } = param;
            let page = (query === null || query === void 0 ? void 0 : query.page) || 0, id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CHANGED",
                protected: true
            });
            let orderBy, direction;
            let filter = {};
            cookie && ({ orderBy , direction , status  } = getFilterByKey(cookie));
            !!id && Object.assign(filter, {
                tids: [
                    id
                ]
            });
            !!status && Object.assign(filter, {
                status
            });
            !!orderBy && (entities.orderBy = orderBy);
            !!direction && (entities.direction = direction);
            return entities;
        };
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(MediaTools, withProps(input), withWebrisksDeleting, withProps((param)=>{
            let { webrisksDelete , query , cookie , ...props } = param;
            var ref;
            return {
                onDeletes: onDeletes(webrisksDelete, props),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), withProps((param)=>{
            let { entities , action  } = param;
            const refetch = ()=>{
                entities && entities.refetch && entities.refetch();
            };
            action.callback = refetch;
            return {
                refetch
            };
        }), ...withPropsBefore || [], withWebrisks((param)=>{
            let { entities  } = param;
            return {
                webrisks: entities
            };
        }), ...withPropsInput || [], withLoading)(Route);
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { req , res , path , query , apolloClient  } = param;
            let ctx = {}, variables = {};
            let cookie = getCookie("webrisk_list_sort", {
                req,
                res
            });
            let entities = getEntities({
                path,
                query,
                cookie
            });
            variables = entities.variables;
            /** Preload taxonomy **/ let result = await apolloClient.query({
                query: WEBRISKS_QUERY,
                fetchPolicy: "no-cache",
                variables
            });
            /** set cache taxonomy **/ await apolloClient.writeQuery({
                query: WEBRISKS_QUERY,
                data: result === null || result === void 0 ? void 0 : result.data,
                variables
            });
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
};
export default WebrisksCompose;
