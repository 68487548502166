"use strict";
module.exports = {
    "en": {
        "text": "SCAN ME",
        "gradient": {
            "type": {
                "label": "Label",
                "linear": "Linear",
                "radial": "Radial"
            }
        }
    },
    "vi": {
        "text": "Qu\xe9t đi chờ chi",
        "qrcode": {
            "font": {
                "title": "Font",
                "roboto": "Roboto",
                "dosis": "Dosis",
                "openSans": "Open Sans",
                "lobster": "Lobster",
                "notoSerif": "Noto Serif",
                "dancingScript": "Dancing Script"
            }
        },
        "colors": {
            "title": "Chọn kiểu m\xe0u",
            "used": "M\xe0u đ\xe3 d\xf9ng",
            "board": "Bảng m\xe0u",
            "custom": "Tuỳ chỉnh m\xe0u"
        },
        "design": {
            "default": "Đặt QR l\xe0m mặc định",
            "save": "Lưu lại thiết kế"
        },
        "edit": {
            "download": "Chỉnh sửa v\xe0 tải xuống Qr Code",
            "label": "C\xe0i đặt QR CODE"
        },
        "gradient": {
            "type": {
                "label": "Chọn kiểu gradient",
                "linear": "Linear",
                "radial": "Radial"
            }
        },
        "toolbars": {
            "card": "Danh thiếp",
            "design": "Giao diện",
            "frame": "Khung QR",
            "data": "Dữ liệu",
            "eye": "Hoa văn",
            "logo": "Logo",
            "color": "M\xe0u sắc",
            "menu": "Menu"
        },
        "label": {
            "frame": "Khung QR",
            "data": "Dữ liệu",
            "eye": "Hoa văn",
            "logo": "Logo",
            "colors": "M\xe0u sắc",
            "menu": "Menu",
            "popup": {
                "font": "Font",
                "download": "Download Qr Code"
            }
        },
        "title": {
            "frame": "Khung QR",
            "data": "Dữ liệu",
            "eye": "Hoa văn",
            "logo": "Logo",
            "colors": "M\xe0u sắc",
            "menu": "Menu"
        },
        "color": "M\xe0u sắc",
        "eye": "Hoa văn",
        "data": "Dữ liệu",
        "frame": "Khung QR",
        "eyes": {
            "top_left": "Hoa văn 1",
            "top_right": "Hoa văn 2",
            "bottom_left": "Hoa văn 3",
            "eyeShape": "Kiểu Khung hoa văn {{value}}",
            "eyeInner": "Kiểu mắt hoa văn {{value}}",
            "color": {
                "shape": "Khung",
                "inner": "Mắt"
            }
        },
        "eyeShape": {
            "top_left": {
                "type": "Hoa văn"
            },
            "top_right": {
                "type": "Hoa văn"
            },
            "bottom_left": {
                "type": "Hoa văn"
            }
        },
        "eyeInner": {
            "top_left": {
                "type": "Mắt"
            },
            "top_right": {
                "type": "Mắt"
            },
            "bottom_left": {
                "type": "Mắt"
            }
        }
    }
};
