import { default as Domains } from "./Domains";
export const map = (param)=>{
    let { id , name , hostname , hash , hash_raw , scheme , verify_type , parent_id , status , weight , childrenItems , childrenCount , uid , author , is_default , is_home_site , connect , created , redirect , access  } = param;
    let children = {
        edges: childrenItems.map((item)=>map(item)),
        count: childrenCount
    };
    return {
        ...access,
        id,
        name,
        hostname,
        hash,
        hash_raw,
        scheme,
        verify_type,
        parent_id,
        status,
        weight,
        children,
        uid,
        author,
        is_default,
        is_home_site,
        connect,
        created,
        redirect
    };
};
class Access {
    /**
   * @param accessPublic
   * @param accessSSL
   * @param accessActive
   * @param accessBlock
   * @param accessUnblock
   * @param accessRedirect
   * @param accessDelete
   * @param accessUpdate
   * @param accessAdmin
   * @param accessManager
   */ set access(param) {
        let { accessPublic , accessSSL , accessActive , accessBlock , accessUnblock , accessRedirect , accessDelete , accessUpdate , accessAdmin , accessManager  } = param;
        this.accessSSL = accessSSL;
        this.accessSSL = accessSSL;
        this.accessBlock = accessBlock;
        this.accessUnblock = accessUnblock;
        this.accessRedirect = accessRedirect;
        this.accessDelete = accessDelete;
        this.accessUpdate = accessUpdate;
        this.accessAdmin = accessAdmin;
        this.accessManager = accessManager;
        this.accessActive = accessActive;
        this.accessPublic = accessPublic;
    }
    /**
   *
   */ get access() {
        return {
            accessSSL: this.accessSSL,
            accessBlock: this.accessBlock,
            accessUnblock: this.accessUnblock,
            accessRedirect: this.accessRedirect,
            accessDelete: this.accessDelete,
            accessUpdate: this.accessUpdate,
            accessAdmin: this.accessAdmin,
            accessManager: this.accessManager,
            accessActive: this.accessActive,
            accessPublic: this.accessPublic
        };
    }
}
export class Domain extends Access {
    static instanse(data) {
        return new this().save(data || {});
    }
    /**
   *
   * @param children
   * @param props
   */ save(param) {
        let { children , ...props } = param;
        this._children = Domains.instanse();
        this._children.update(children);
        this.access = props;
        this.info = props;
        return this;
    }
    /**
   *
   * @param id
   * @param name
   * @param hostname
   * @param hash
   * @param hash_raw
   * @param ssl
   * @param scheme
   * @param verify_type
   * @param parent_id
   * @param status
   * @param weight
   * @param isPublic
   * @param uid
   * @param author
   * @param is_default
   * @param is_home_site
   * @param connect
   * @param created
   * @param redirect
   */ set info(param) {
        let { id , full , name , hostname , hash , hash_raw , ssl , scheme , verify_type , parent_id , status , weight , isPublic , uid , author , is_default , is_home_site , connect , created , redirect  } = param;
        this._id = id;
        this.name = name;
        this.full = full;
        this._status = status;
        this._weight = weight;
        this.ssl = ssl;
        this._scheme = scheme;
        this._schemeRaw = scheme;
        this._uid = uid;
        this._author = author;
        this._hostname = hostname;
        this._hash = hash;
        this._hash_raw = hash_raw;
        this._created = created;
        this._is_default = is_default;
        this._connect = connect;
        this._type = verify_type;
        this._parent_id = parent_id;
        this.is_home_site = is_home_site;
        this.isPublic = isPublic;
        this.redirect = redirect;
    }
    get childrenCount() {
        return this._children.count || 0;
    }
    get childrenItems() {
        return this._children.items;
    }
    get children() {
        return this._children;
    }
    get id() {
        return this._id || 0;
    }
    get type() {
        return this._type || "ns";
    }
    get scheme() {
        if (this._scheme === "upload") {
            return "https";
        }
        return this._scheme || "http";
    }
    get schemeRaw() {
        return this._schemeRaw || "http";
    }
    get hostname() {
        return this._hostname || "";
    }
    get hash() {
        return this._hash || "";
    }
    get hashRaw() {
        return this._hash_raw || "";
    }
    get status() {
        return this._status || "inactive";
    }
    get weight() {
        return this._weight || 0;
    }
    get uid() {
        return this._uid || 0;
    }
    get parent_id() {
        return this._parent_id || 0;
    }
    get author() {
        return this._author || "";
    }
    get created() {
        return this._created * 1000 || null;
    }
    get site() {
        return "".concat(this.scheme, "://").concat(this.hostname);
    }
    get is_default() {
        return !!this._is_default;
    }
    get connect() {
        return this._connect || [];
    }
}
export default Domain;
