"use strict";
module.exports = {
    "en": {
        "navLink": "Gen nav link",
        "title": "Gen module",
        "meta": "Gen example",
        "welcomeText": "Hello, This is the Gen module"
    },
    "vi": {
        "navLink": "Gen nav link",
        "title": "Gen module",
        "meta": "Gen example",
        "userEdit": {
            "form": {
                "sex": {
                    "other": "Kh\xe1c",
                    "female": "Nữ",
                    "male": "Nam"
                },
                "field": {
                    "parents": "Cha hoặc mẹ",
                    "spouses": "Kết h\xf4n",
                    "note": "Ghi ch\xfa",
                    "address": "Địa chỉ",
                    "name": "Họ v\xe0 t\xean",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "deathday": "Ng\xe0y mất",
                    "phone": "Số điện thoại",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử"
                }
            }
        },
        "form": {
            "direction": {
                "horizontal": "Chiều ngang",
                "vertical": "Chiều dọc"
            },
            "actions": {
                "cancel": "Huỷ",
                "delete": "Xo\xe1"
            },
            "confirm": {
                "delete": {
                    "label": "Xo\xe1 th\xe0nh vi\xean trong gia đ\xecnh."
                }
            }
        },
        "create": {
            "form": {
                "field": {
                    "name": "T\xean d\xf2ng họ",
                    "note": "Nội dung ghi ch\xfa"
                }
            }
        },
        "menu": {
            "title": "Lựa chọn",
            "edit": "Sửa",
            "repos": "Thay đổi toạ độ",
            "direction": "Điều chỉnh hướng",
            "settings": "Điều chỉnh theme",
            "add": {
                "children": "Th\xeam con",
                "spouses": "Th\xeam vợ/chồng"
            },
            "delete": "Xo\xe1 th\xe0nh vi\xean"
        }
    }
};
