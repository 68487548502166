import { default as Domain } from "./Domain";
import Entities from "@gqlapp/core-common/Entities";
import fetchMore from "@xem/entity/classes/fetchMore";
export class Domains extends Entities {
    set fetchMore(value) {
        this._fetchMore = value;
    }
    get fetchMore() {
        return fetchMore(this._fetchMore);
    }
    /**
   * instanse
   */ static instanse(props) {
        return new this(props);
    }
    get domains() {
        return this.items.map((param)=>{
            let { id , scheme , hostname  } = param;
            return {
                value: id,
                label: "".concat(scheme, "://").concat(hostname)
            };
        });
    }
    get value() {
        return this.items.filter((param)=>{
            let { is_default  } = param;
            return !!is_default;
        }).map((param)=>{
            let { id , scheme , hostname  } = param;
            return {
                value: id,
                label: "".concat(scheme, "://").concat(hostname)
            };
        }).shift();
    }
    constructor(props){
        super(props);
        this._fetchMore = null;
        this.interface = Domain;
    }
}
export default Domains;
