import withProps from "recompose/withProps";
import compose from "recompose/compose";
import mapInput from "@gqlapp/base/utils/mapInput";
import { graphql } from "react-apollo";
import DOMAINS_QUERY from "@xem/webrisk-common/graphql/webrisksQuery.graphql";
/**
 *
 * @param input
 */ export const withWebrisks = (input)=>compose(withProps((props)=>mapInput(input, props)), withProps((param)=>{
        let { webrisks: { variables  }  } = param;
        return {
            ...variables
        };
    }), graphql(DOMAINS_QUERY, {
        options: (param)=>{
            let { page , pageSize , orderBy , direction , filter , fetchPolicy  } = param;
            return {
                variables: {
                    page,
                    pageSize,
                    orderBy,
                    direction,
                    filter
                },
                fetchPolicy: fetchPolicy || null
            };
        },
        props (param) {
            let { data: { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  } , ownProps: { webrisks  }  } = param;
            webrisks.refetch = refetch;
            webrisks.fetchMore = fetchMore;
            webrisks.subscribeToMore = subscribeToMore;
            webrisks.updateQuery = updateQuery;
            if (!loading && res) {
                webrisks.update(res);
            }
            return {
                loading,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
export default withWebrisks;
