import { map } from "../config";
import { menuMap } from "./Utils";
import ClientModule from "@gqlapp/module-client-react";
const userWeb = [
    {
        weight: 1,
        name: "login",
        className: "px-2 mr-2 ml-0",
        url: map({
            path: "login"
        }),
        tag: "span",
        itemProps: {
            className: "btn btn-link px-2 mr-2 ml-0"
        }
    },
    {
        weight: 2,
        name: "free-register",
        className: "px-2 mr-2 ml-0",
        url: map({
            path: "register"
        }),
        tag: "span",
        itemProps: {
            className: "btn btn-link px-2 mr-2 ml-0",
            style: {
                "--link-color": "#0066CC"
            }
        }
    }
].map(menuMap).map((value)=>{
    Object.assign(value, {
        perms: [
            "isAnonymous"
        ]
    });
    return value;
});
export default new ClientModule({
    menus: [
        {
            positions: [
                "web.user"
            ],
            items: userWeb
        }
    ]
});
