import queryString from "query-string";
import config from "../index";
class Settings {
    init() {
        this.options = {};
        this.libs = [];
        this.setJS("public", config.app.publicPath || "/");
    }
    // @ts-ignore
    addJS(settings) {
        Object.assign(this.options, settings);
    }
    // @ts-ignore
    setJS(name, settings) {
        let _OPTIONS = {};
        _OPTIONS[name] = settings;
        Object.assign(this.options, _OPTIONS);
    }
    addLibrary(path) {
        let options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        let publicPath = config.app.publicPath || "/";
        if (!path.match("/^/(.*)/im") && !path.match(/\b(?:https?:\/\/)?(?:w{3}\.)(.*)/i)) {
            path = "".concat(publicPath).concat(path);
        }
        if (!Object.keys(this.libs).includes(path)) {
            this.libs[path] = options || {
                weight: 1
            };
        }
    }
    toJSON() {
        return this.options;
    }
    toString() {
        return JSON.stringify(this.options);
    }
    // @ts-ignore
    toLibs() {
        let libs = [];
        Object.keys(this.libs).map((value)=>{
            if (this.hasHashtag(value)) {
                let regexp = /#\S+/g;
                value = value.replace(regexp, "");
            }
            let { withoutTime , ...props } = this.libs[value];
            if (!withoutTime) {
                value += this.hasPlus(value) ? "&" : "?";
                value += queryString.stringify({
                    t: new Date().getTime()
                });
            }
            libs.push({
                ...props,
                src: value
            });
        });
        return (libs || []).sort((a, b)=>a.weight - b.weight).map((param)=>{
            let { path , type , weight , ...value } = param;
            return {
                ...value,
                type: type || "text/javascript"
            };
        });
    }
    headScripts() {
        return this.toLibs().filter((param)=>{
            let { kind  } = param;
            return kind === "head";
        }).map((param)=>{
            let { kind , ...props } = param;
            return props;
        });
    }
    footerScripts() {
        return this.toLibs().filter((param)=>{
            let { kind  } = param;
            return kind !== "head";
        }).map((param)=>{
            let { kind , ...props } = param;
            return props;
        });
    }
    constructor(options = {}){
        this.options = {};
        this.libs = [];
        // @ts-ignore
        this.hasHashtag = (url)=>/\#/i.test(url);
        // @ts-ignore
        this.hasPlus = (url)=>/\?/i.test(url);
        this.options = options || {};
    }
}
Settings.createInstance = function() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return new Settings(options);
};
export default Settings;
