import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import Entities from "@xem/store-common/classes/Products";
import withProducts, { PRODUCTS_QUERY } from "@xem/store-common/classes/withProducts";
import withProductsDeleting from "@xem/store-common/classes/withProductsDeleting";
import onDeletes from "./utils/onDeletes";
import { getFilterByKey } from "./Filter";
import MediaTools from "./MediaTools";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const ModulesCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        const getEntities = function getPath(param) {
            let { query , cookie , status ="active"  } = param;
            let id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CHANGED",
                protected: true
            });
            let orderBy, direction;
            let filter = {};
            cookie && ({ orderBy , direction , status  } = getFilterByKey(cookie));
            !!id && Object.assign(filter, {
                tids: [
                    id
                ]
            });
            !!status && Object.assign(filter, {
                status
            });
            !!orderBy && (entities.orderBy = orderBy);
            !!direction && (entities.direction = direction);
            return entities;
        };
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(MediaTools, withProps(input), withProductsDeleting, withProps((param)=>{
            let { productsDelete , query , cookie , ...props } = param;
            var ref;
            return {
                onDeletes: onDeletes(productsDelete, props),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), withProps((param)=>{
            let { entities , action  } = param;
            const refetch = ()=>{
                entities && entities.refetch && entities.refetch();
            };
            action.callback = refetch;
            return {
                refetch
            };
        }), ...withPropsBefore || [], withProducts((param)=>{
            let { entities  } = param;
            return {
                products: entities
            };
        }), ...withPropsInput || [], withLoading)(Route);
        const setCache = async (param)=>{
            let { apolloClient , query , variables  } = param;
            /** Preload taxonomy **/ let result = await apolloClient.query({
                query,
                variables,
                fetchPolicy: "no-cache"
            });
            /** set cache taxonomy **/ await apolloClient.writeQuery({
                query,
                variables,
                data: result === null || result === void 0 ? void 0 : result.data
            });
        };
        const bindCache = async (apolloClient, query, obj)=>{
            let entities = getEntities(obj);
            let variables = entities.variables;
            /** set link cache*/ await setCache({
                query,
                variables,
                apolloClient
            });
        };
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { apolloClient  } = param;
            let ctx = {};
            await bindCache(apolloClient, PRODUCTS_QUERY, {});
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
};
export default ModulesCompose;
