import React from "react";
import loadable from "next/dynamic";
const SettingContainer = loadable(()=>import("./Setting").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Setting")
            ]
    }
});
export default SettingContainer;
