import Image from "@gqlapp/core-common/Image";
import isArray from "@gqlapp/base/utils/isArray/isArray";
export class Taxonomy {
    /**
   *
   */ static instanse(data) {
        return new this().save(data || {});
    }
    /**
   *
   */ reset() {
        const $self = this;
        Object.assign($self, new Taxonomy());
    }
    /**
   *
   * @param id
   * @param hierarchy
   * @param name
   * @param weight
   * @param author
   * @param parent_id
   * @param images
   * @param icon
   * @param child
   * @param description
   * @param canDelete
   * @param canUpdate
   * @param canView
   * @param status
   * @param views
   * @param links
   * @param assigned
   */ save(param) {
        let { id , hierarchy , name , weight , author , parent_id , images , icon , child , description , canDelete , canUpdate , canView , status , views , links , assigned  } = param;
        this.id = id || 0;
        this.name = name || 0;
        this.author = author || "";
        this.hierarchy = hierarchy || "";
        this.weight = weight || 0;
        this.parent_id = parent_id || 0;
        this.child = child || 0;
        this.description = description || "";
        this.status = status || true;
        this.canDelete = canDelete || false;
        this.canUpdate = canUpdate || false;
        this.canView = canView || false;
        this.icon = icon || false;
        this.views = views;
        this.links = links;
        this.images = images || [];
        this.assigned = assigned;
        return this;
    }
    /**
   *
   * @param value
   */ set assigned(value) {
        this._assigned = value || [];
    }
    get assigned() {
        return this._assigned;
    }
    /**
   * @param _images
   */ set images(_images) {
        try {
            const images = [];
            if (isArray(_images)) {
                _images.map((img)=>images.push(img));
            } else {
                images.push(_images);
            }
            this._images = images;
        } catch (e) {
            console.warn(e.toString());
        }
    }
    /**
   * View
   */ get views() {
        return this._views || 0;
    }
    set views(value) {
        this._views = value;
    }
    /**
   * Lunk
   */ get links() {
        return this._links || 0;
    }
    set links(value) {
        this._links = value;
    }
    /**
   * Images
   */ get images() {
        return this._images.filter((image)=>image);
    }
    /**
   * image
   */ get image() {
        return this._images[0] || new Image();
    }
    /**
   *
   * @param data
   */ constructor(data = null){
        this.id = 0;
        this.hierarchy = "";
        this.icon = "";
        this.name = "";
        this.description = "";
        this.author = "";
        this.weight = 0;
        this.parent_id = 0;
        this.status = false;
        this.canDelete = false;
        this.canUpdate = false;
        this.canView = false;
        this._images = [];
        if (data) {
            this.save(data);
        }
    }
}
export default Taxonomy;
