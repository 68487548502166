import { graphql } from "react-apollo";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapInput from "@gqlapp/base/utils/mapInput";
import statsChartMore from "./Stats/statsChartMore";
import STATS_CHART_QUERY from "../graphql/StatsChart.graphql";
/**
 * withStatsChart
 * @param input
 */ export const withStatsChart = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), graphql(STATS_CHART_QUERY, {
            skip: (param)=>{
                let { skip  } = param;
                return !!skip;
            },
            options: (param)=>{
                let { id , to , day , dateType , chartProps  } = param;
                return {
                    variables: {
                        id,
                        to,
                        day,
                        dateType,
                        ...chartProps
                    }
                };
            },
            props (param) {
                let { data: { loading , charts: contents , error , fetchMore  } , ownProps , ownProps: { charts  }  } = param;
                if (!loading && charts) {
                    charts.update(contents);
                }
                return {
                    loading,
                    errors: error ? error.graphQLErrors : null,
                    statsChartMore: statsChartMore(charts, fetchMore, ownProps)
                };
            }
        }))(Component);
export default withStatsChart;
