import getConfig from "next/config";
const { publicRuntimeConfig  } = getConfig();
export default (()=>{
    let config = {
        path: "/"
    };
    if (publicRuntimeConfig.secure) {
        config = {
            ...config,
            secure: true
        };
    }
    if (!publicRuntimeConfig.disableExpires) {
        let MAX_AGE = publicRuntimeConfig.maxAge || 24;
        MAX_AGE *= 60 * 60;
        // const expires = Date.now() + MAX_AGE * 1000;
        config = {
            ...config,
            maxAge: MAX_AGE
        };
    }
    return config;
});
