import { graphql } from "react-apollo";
import LINK_UPLOAD from "../graphql/linkImageUpload.graphql";
import loading from "@gqlapp/base/utils/loading";
/**
 * withLinkUpload
 */ export const withLinkUpload = graphql(LINK_UPLOAD, {
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            uploadFile: async (param)=>{
                let { file  } = param;
                return loading(loadbar, async ()=>{
                    try {
                        const { data  } = await mutate({
                            variables: {
                                file
                            },
                            fetchPolicy: "no-cache"
                        });
                        return data === null || data === void 0 ? void 0 : data.res;
                    } catch (e) {
                        return null;
                    }
                });
            }
        };
    }
});
export default withLinkUpload;
