import loading from "@gqlapp/base/utils/loading";
/**
 *
 * @param fetchMore
 */ export const entitiesMore = (fetchMore)=>async (variables, loadbar)=>new Promise(async (resolve, reject)=>loading(loadbar, async ()=>{
                try {
                    await fetchMore({
                        variables,
                        updateQuery: async (_, param)=>{
                            let { fetchMoreResult: { res  }  } = param;
                            res = res || [];
                            resolve(res);
                        }
                    });
                } catch (e) {
                    error(e);
                    reject({});
                }
            }));
export default entitiesMore;
