require('./env');
require('./logger');
require('./cookie');
require('./detect');
require('./define');
require('./String');
require('./Array');
require('./Number');
try{
  process.setMaxListeners(0);
  process.on('uncaughtException', ex => {
    error(ex);
    process.exit(1);
  });

  process.on('unhandledRejection', reason => {
    error(reason);
  });
}catch (e) {

}
