import React from "react";
import moment from "moment-timezone";
import { getItem, setItem } from "@gqlapp/core-common/clientStorage";
export default function RootComponent(param) {
    let { children , session , ...props } = param;
    let { key , value  } = session || {};
    let guess = moment.tz.guess();
    setCookie("tz", guess);
    /** preload cookie*/ React.useEffect(()=>{
        if (key && value && !hasCookie(key)) {
            let allCookies = getCookies();
            let keys = Object.keys(allCookies) || [];
            keys.filter((k)=>new RegExp(RegExp("(SESS|SSESS)(.*)", "s")).test(k)).map(function(value) {
                deleteCookie(value);
            });
            setCookie(key, value);
        }
    }, [
        key,
        value
    ]);
    try {
        if (window) {
            getItem("data").then((data)=>{
                if (!data && (props === null || props === void 0 ? void 0 : props.localKey)) {
                    setItem("data", props === null || props === void 0 ? void 0 : props.localKey);
                }
            });
        }
    } catch (e) {}
    return children;
}
