import ClientModule from "@gqlapp/module-client-react";
// @ts-ignore
const map = (param)=>{
    let { name , onClick , path , parent , value , ...rest } = param;
    return function() {
        let { query: { id  } = {} , ...self } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, callback = arguments.length > 1 ? arguments[1] : void 0;
        return callback({
            ...rest,
            itemProps: {
                className: "btn btn-link px-2 mr-2 ml-0"
            },
            weight: value,
            id: "menu-".concat(name),
            // @ts-ignore
            name: "".concat(name).t({}),
            // @ts-ignore
            title: "".concat(name).t({}),
            className: [
                "m-0 p-0"
            ],
            parent: parent || [
                {
                    targetId: 0
                }
            ]
        });
    };
};
const menus = [
    {
        weight: 1,
        name: "shortlink",
        url: "/",
        onClick: null
    },
    {
        weight: 2,
        name: "Social text",
        onClick: null,
        url: "/bold-italic-facebook"
    }
].map(map);
// @ts-ignore
export default new ClientModule({
    menus: [
        {
            positions: [
                "web.header",
                "cpanel.header"
            ],
            items: menus
        }
    ]
});
