import { graphql } from "react-apollo";
import CREATE from "../graphql/CustomPageCreate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";
export default graphql(CREATE, {
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            pageCreate: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                        variables
                    }), (param)=>{
                        let { data  } = param;
                        return data;
                    }), (param)=>{
                        let { res  } = param;
                        return res;
                    }))
        };
    }
});
