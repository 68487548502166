import { graphql } from "react-apollo";
import mapInput from "@gqlapp/base/utils/mapInput";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import LINK_QUERY from "../graphql/linkQuery.graphql";
export { LINK_QUERY };
/**
 * PageSearch
 * @param subscribeToMore
 * @constructor
 */ export const linkLoad = (subscribeToMore)=>(variables)=>new Promise(async (resolve)=>{
            try {
                subscribeToMore({
                    document: LINK_QUERY,
                    variables: variables,
                    updateQuery: async (_, param)=>{
                        let { subscriptionData: { data: { res  }  }  } = param;
                        resolve(res);
                    }
                });
            } catch (e) {
                resolve({});
            }
        });
export const queryLink = graphql(LINK_QUERY, {
    skip: (param)=>{
        let { skip , skipLink , path  } = param;
        return !path || skip || skipLink;
    },
    options: (param)=>{
        let { path , fetchPolicy  } = param;
        return {
            fetchPolicy: fetchPolicy || null,
            variables: {
                path
            }
        };
    },
    props (param) {
        let { data: { loading , res , refetch , error , subscribeToMore  } , ownProps: { link  }  } = param;
        !!link && (link.refetch = refetch);
        !!link && (link.subscribeToMore = subscribeToMore);
        // @ts-ignore
        if (!loading && res && link) {
            link.save(res);
        }
        return {
            loading,
            linkLoading: loading,
            errors: error ? error.graphQLErrors : null
        };
    }
});
/**
 * withLink
 * @param input
 */ export const withLink = (input)=>compose(withProps((props)=>mapInput(input, props)), queryLink);
export default withLink;
