import dynamic from "next/dynamic";
import renderCallback from "../renderCallback";
const Render = dynamic(()=>import("./View").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./View")
            ]
    }
});
export default function Button(ref) {
    return renderCallback(ref, "html", Render);
}
