// import { locationMore, statsMore } from "./StatsOperations";
export class Stats {
    /**
   *
   */ static instanse() {
        return new this();
    }
    /**
   * get IP
   */ get ips() {
        const _IPS = [];
        let location = this._location || [];
        let ips = location.map((param)=>{
            let { ip  } = param;
            return Number(ip) || 0;
        });
        ips.map((ip)=>!_IPS.includes(ip) ? _IPS.push(ip) : "");
        return _IPS;
    }
    /**
   * get locations
   */ get locations() {
        return this._location || [];
    }
    /**
   * set locations
   * @param value
   */ set locations(value) {
        const _LOCATIONS = [];
        let { edges  } = value || {};
        edges = edges || [];
        if (edges.length) {
            edges.map((value)=>{
                _LOCATIONS.push(value);
            });
        }
        this._location = _LOCATIONS;
    }
    /**
   * get locations
   */ get heatmap() {
        return this._heatmap || [];
    }
    /**
   * set locations
   * @param value
   */ set heatmap(value) {
        const _HEATMAP = [];
        let { edges  } = value || {};
        edges = edges || [];
        edges.map((val)=>_HEATMAP.push(val));
        this._heatmap = _HEATMAP;
    }
    /**
   *
   */ get devices() {
        return this._devices || [];
    }
    /**
   *
   */ set devices(values) {
        let { edges  } = values || {};
        edges = edges || [];
        const _DEVICES = [];
        if (edges.length) {
            edges.map((param)=>{
                let { name , count , percent  } = param;
                _DEVICES.push({
                    name: name,
                    count: count || 0,
                    percent: Math.round(count * 100 / percent) || 0
                });
            });
        }
        this._devices = _DEVICES;
    }
    /**
   *
   */ get channels() {
        return this._channels || [];
    }
    /**
   *
   * @param value
   */ set channels(value) {
        let { views: totalPageView , users: totalUsers , newUsers: totalNewUsers , edges  } = value || {};
        edges = edges || [];
        const _CHANNELS = [];
        if (edges.length) {
            edges.map((param)=>{
                let { name , pageView , users , newUsers  } = param;
                _CHANNELS.push({
                    name: {
                        value: name
                    },
                    pageView: {
                        value: pageView,
                        pecent: totalPageView ? Math.round(pageView * 100 / totalPageView) : 0
                    },
                    users: {
                        value: users,
                        pecent: totalUsers ? Math.round(users * 100 / totalUsers) : 0
                    },
                    newUsers: {
                        value: newUsers,
                        pecent: totalNewUsers ? Math.round(newUsers * 100 / totalNewUsers) : 0
                    }
                });
            });
        }
        this._channels = _CHANNELS;
    }
    /**
   *
   */ get data() {
        return this._stats || {};
    }
    /**
   *
   * @param value
   */ set data(value) {
        const { views , links , ViewsByXemLink , linksByXemLink , viewByDomains , linksByDomains , linksByGuest  } = value || {};
        this._stats = {
            views,
            links,
            ViewsByXemLink,
            linksByXemLink,
            viewByDomains,
            linksByDomains,
            linksByGuest
        };
    }
    save(param) {
        let { data , locations , channels  } = param;
        if (typeof data !== "undefined") {
            this.data = data;
        }
        if (typeof locations !== "undefined") {
            this.locations = locations;
        }
        if (typeof channels !== "undefined") {
            this.channels = channels;
        }
    }
}
export default Stats;
