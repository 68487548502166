import { graphql } from "react-apollo";
import TAXONOMY_DELETE from "../graphql/TaxonomyDelete.graphql";
import loading from "@gqlapp/base/utils/loading";
export const withTaxonomyDeleting = (Component)=>graphql(TAXONOMY_DELETE, {
        props: (param)=>{
            let { mutate , ownProps: { loadbar  }  } = param;
            return {
                taxonomyDelete: async (id)=>loading(loadbar, async ()=>mutate({
                            variables: {
                                id
                            }
                        }))
            };
        }
    })(Component);
export default withTaxonomyDeleting;
