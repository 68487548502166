import compose from "recompose/compose";
import Button from "./Button/Render";
import Html from "./Html/Render";
import Divider from "./Divider/Render";
import Form from "./Form/Render";
import Social from "./Social/Render";
// import Text from "./Text";
// import Avatar from "./Avatar";
// import Carousel from "./Carousel";
// import Countdown from "./Countdown";
// import Faq from "./Faq";
// import Maps from "./Maps";
// import MediaAndText from "./MediaAndText";
// import Messaging from "./Messaging";
// import Music from "./Music";
// import Page from "./Page";
// import Products from "./Products";
// import Social from "./Social";
//
// import Tool from "./Tools";
// import Radius from "../settings/Radius";
// import Shadow from "../settings/Shadow";
// import Offset from "../settings/Offset";
// import Video from "./Video";
// import Vcard from "./Vcard";
export default compose(Button(), Social(), Html(), Divider(), Form());
