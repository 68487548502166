import React from "react";
import loadable from "next/dynamic";
import UsersCompose from "../helpers/UsersCompose";
import withProps from "recompose/withProps";
import { map } from "../helpers/config";
import withFormFilter from "../helpers/Filter";
const ListContainer = loadable(()=>import("./List").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./List")
            ]
    }
});
export default UsersCompose({}, [
    withProps((param)=>{
        let { query  } = param;
        return {
            pageProps: {
                path: map({
                    path: "list"
                }),
                other: map({
                    path: "listpage",
                    page: ":page"
                }),
                query
            }
        };
    }),
    withFormFilter,
    withProps((param)=>{
        let { setFieldValue , entities , onDeletes , onUpdates  } = param;
        return {
            onBlock: (id)=>onUpdates({
                    ids: [
                        id
                    ],
                    input: {
                        status: false
                    }
                }),
            onUnblock: (id)=>onUpdates({
                    ids: [
                        id
                    ],
                    input: {
                        status: true
                    }
                }),
            onDelete: (id)=>onDeletes({
                    ids: [
                        id
                    ]
                }),
            refetch: ()=>{
                try {
                    entities.refetch();
                } catch (e) {}
                try {
                    setFieldValue("page", 1);
                } catch (e1) {}
            }
        };
    })
])(ListContainer);
