import compose from "recompose/compose";
import withOnEditCampaign from "./withOnEditCampaign";
import withOnEditCustom from "./withOnEditCustom";
import withOnEditAdvanced from "./withOnEditAdvanced";
import withOnEditProtected from "./withOnEditProtected";
import withOnEditRedirect from "./withOnEditRedirect";
import withOnEditDisplay from "./withOnEditDisplay";
import withOnFilter from "./withOnFilter";
import withRenderProcess from "@xem/entity/classes/withRenderProcess";
import withRenderTools from "@xem/look/Renders/Tools/withTools";
export const withListProcess = (Component)=>compose(withRenderTools, withOnFilter, withOnEditCampaign, withOnEditCustom, withOnEditAdvanced, withOnEditProtected, withOnEditRedirect, withOnEditDisplay)(Component);
const CpanelMediaTools = compose(withListProcess, withRenderProcess({
    entityType: "link"
}));
export default CpanelMediaTools;
