"use strict";
module.exports = {
    "en": {
        "domain": {
            "title": "List of domains",
            "label": "Domain name"
        },
        "breadcrumb": {
            "title": "List of domains"
        },
        "brand": {
            "choose": "Choose a brand domain name",
            "add": "Add brand domain",
            "verify": {
                "ownership": "Verify brand domain ownership via DNS settings"
            }
        },
        "URL": {
            "minimum_url_length": "Url is too short.",
            "invalid": "Invalid URL."
        },
        "form": {
            "files": {
                "fullchain": {
                    "invalid": "You need to upload the fullchain.pem file"
                },
                "privkey": {
                    "invalid": "You need to upload the privkey.pem file"
                }
            },
            "label": {
                "domain": "Choose domain name",
                "scheme": "SSL certificate",
                "name": "Domain name",
                "direct": {
                    "url": "Direct Link"
                }
            },
            "field": {
                "http": "http",
                "https": "https:// free",
                "upload": "https:// buy"
            }
        },
        "validation": {
            "brand": {
                "required": "Please enter the brand domain name",
                "min": "Brand domain names must have at least {{value}} characters."
            },
            "illegal": "Invalid domain name."
        },
        "domains": {
            "title": "List of domains",
            "column": {
                "name": "Brand Domain",
                "status": "Status"
            }
        },
        "label": {
            "filter": "Display Arrangement"
        },
        "views": {
            "brand": "Newly created brand domain",
            "az": "Order from A to Z",
            "za": "Order from Z to A"
        },
        "btn": {
            "delete": "Delete domain name",
            "block": "Domain block",
            "active": "Verified",
            "apply": "Apply"
        },
        "list": {
            "title": "List of domains",
            "btn": {
                "public": "Domain: set public",
                "not_public": "Domain: Set none public",
                "add": "Add brand domain",
                "edit": "Edit domain",
                "connect": "Add Root Domain",
                "delete": "Delete domain name",
                "redirect": "Redirect brand domain",
                "ssl": "SSL Certificate",
                "block": "Temporarily block the domain",
                "unblock": "Unlock brand domain",
                "active": "Verified"
            },
            "column": {
                "name": "Name",
                "scheme": "URL scheme",
                "blocked": "Locked",
                "default": "Default",
                "home_site": "Forward home page",
                "active": "Verified",
                "pending": "Verifying",
                "inactive": "Not verified",
                "hostname": "Brand domain name",
                "status": "Status",
                "connect": "Root domain",
                "created": "Creation date",
                "actions": ""
            }
        },
        "errorMsg": {
            "delete": "There was a problem, it cannot be deleted",
            "update": "There was a problem, unable to update",
            "block": "Problem, cannot block",
            "verify": "There was a problem, could not be verified",
            "alreadyExists": "There was a problem, this domain already exists",
            "access": {
                "denied": "Problem, you do not have permission to {{value}}"
            }
        },
        "notify": {
            "not_public": 'Are you sure you changed domain status<br /><span style="color: #006621">{{value}}</span> ?',
            "public": 'Are you sure you changed domain status<br /><span style="color: #006621">{{value}}</span> ?',
            "delete": 'Are you sure you will delete {{type}} domain<br /><span style="color: #006621">{{value}}</span> ?',
            "block": 'Are you sure you will block the domain<br /><span style="color: #006621">{{value}}</span>?<br />All shortened links by domain This will not be available.',
            "unblock": 'Are you sure you will unlock the domain<br /><span style="color: #006621">{{value}}</span> ?',
            "active": 'Are you sure you will change domain status<br /><span style="color: #006621">{{value}}</span> to active?',
            "scheme": {
                "upload": "If your domain name has been registered with free ssl or purchased elsewhere, if you want to use it, please upload 2 files: <span>Privkey.pem</span> and <span>Fullchain.pem</ span>",
                "https": "When you add a brand domain to Xem.Link, we\ngenerate a free SSL certificate for you. Sometimes it won't work due to your domain's DNS\nsystem. Please switch to table\n<span>https:// buy</span> to set it up manually."
            },
            "odd": {
                "title": "List of {{value}} domains"
            },
            "even": {
                "title": "List of {{value}} domains"
            },
            "unpublished": {
                "odd": {
                    "title": "List of {{value}} hidden domains"
                },
                "even": {
                    "title": "List of hidden {{value}} domains"
                }
            }
        }
    },
    "vi": {
        "domain": {
            "search.label": "Domain search",
            "search.placeholder": "Domain search",
            "title": "Danh s\xe1ch t\xean miền",
            "label": "T\xean miền"
        },
        "breadcrumb.title": "Danh s\xe1ch t\xean miền",
        "brand": {
            "choose": "Chọn t\xean miền thương hiệu",
            "add": "Th\xeam t\xean miền thương hiệu",
            "verify.ownership": "X\xe1c minh quyền sở hữu t\xean miền thương hiệu qua c\xe0i đặt DNS"
        },
        "url": {
            "minimum_url_length": "Url qu\xe1 ngắn.",
            "invalid": "Url kh\xf4ng hợp lệ."
        },
        "form": {
            "files": {
                "fullchain.invalid": "Bạn cần upload file fullchain.pem",
                "privkey.invalid": "Bạn cần upload file privkey.pem"
            },
            "label": {
                "domain": "Choose domain name",
                "scheme": "SSL certificate",
                "name": "Domain name",
                "direct.url": "Direct Link"
            },
            "field": {
                "http": "http",
                "https": "https:// free",
                "upload": "https:// buy"
            }
        },
        "validation": {
            "illegal": "T\xean miền kh\xf4ng hợp lệ.",
            "brand": {
                "required": "H\xe3y nhập t\xean miền thương hiệu",
                "min": "T\xean miền thương hiệu phải c\xf3 \xedt nhất {{value}} k\xfd tự."
            }
        },
        "domains": {
            "title": "Danh s\xe1ch t\xean miền",
            "column": {
                "name": "Brand Domain",
                "status": "Trạng th\xe1i"
            }
        },
        "label.filter": "Sắp Xếp Hiển Thị",
        "views": {
            "brand": "Brand domain mới tạo",
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "btn": {
            "delete": "Xo\xe1 t\xean miền",
            "block": "Kho\xe1 t\xean miền",
            "active": "Đ\xe3 x\xe1c minh",
            "apply": "\xc1p dụng"
        },
        "list": {
            "title": "Danh s\xe1ch t\xean miền",
            "btn": {
                "public": "Domain: set public",
                "not_public": "Domain: Set none public",
                "add": "Th\xeam t\xean miền thương hiệu",
                "edit": "Chỉnh sửa t\xean miền",
                "connect": "Th\xeam T\xean Miền Gốc",
                "delete": "Xo\xe1 t\xean miền",
                "redirect": "Chuyển hướng brand domain",
                "ssl": "SSL Certificate",
                "block": "Tạm kho\xe1 domain",
                "unblock": "Mở kho\xe1 brand domain",
                "active": "Đ\xe3 x\xe1c minh"
            },
            "column": {
                "name": "T\xean",
                "scheme": "URL scheme",
                "blocked": "Đang kho\xe1",
                "default": "Mặc định",
                "home_site": "Chuyển tiếp trang chủ",
                "active": "Đ\xe3 x\xe1c minh",
                "pending": "Đang x\xe1c minh",
                "inactive": "Chưa x\xe1c minh",
                "hostname": "T\xean miền thương hiệu",
                "status": "Trạng th\xe1i",
                "connect": "T\xean miền gốc",
                "created": "Ng\xe0y khởi tạo",
                "actions": ""
            }
        },
        "errorMsg": {
            "delete": "Gặp sự cố, kh\xf4ng thể xo\xe1 được",
            "update": "Gặp sự cố, kh\xf4ng thể cập nhật được",
            "block": "Gặp sự cố, kh\xf4ng thể kho\xe1",
            "verify": "Gặp sự cố, kh\xf4ng thể x\xe1c minh được",
            "alreadyExists": "Gặp sự cố, t\xean miền n\xe0y đ\xe3 tồn tại",
            "access.denied": "Gặp sự cố, bạn kh\xf4ng c\xf3 quyền {{value}}"
        },
        "notify": {
            "not_public": 'Bạn c\xf3 chắc m\xecnh chuyển trạng th\xe1i domain<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "public": 'Bạn c\xf3 chắc m\xecnh chuyển trạng th\xe1i domain<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "delete": 'Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 {{type}} domain<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "block": 'Bạn c\xf3 chắc m\xecnh sẽ kho\xe1 domain<br /><span style="color: #006621">{{value}}</span> chứ ?<br />Mọi link r\xfat gọn theo domain n\xe0y sẽ<br /> kh\xf4ng khả dụng.',
            "unblock": 'Bạn c\xf3 chắc m\xecnh sẽ mở kho\xe1 domain<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "active": 'Bạn c\xf3 chắc m\xecnh sẽ thay đổi trạng th\xe1i domain<br /><span style="color: #006621">{{value}}</span> sang hoạt động?',
            "scheme": {
                "upload": "Nếu t\xean  miền của bạn đ\xe3 đăng k\xfd ssl free hoặc mua ở nơi kh\xe1c, muốn sử dụng n\xf3 h\xe3y tải l\xean 2 file: <span>Privkey.pem</span> v\xe0 <span>Fullchain.pem</span>",
                "https": "Khi bạn th\xeam brand domain v\xe0o Xem.Link , ch\xfang t\xf4i\nkhởi tạo cho c\xe1c bạn một chứng chỉ SSL certificate \nmiễn ph\xed. Đ\xf4i khi n\xf3 sẽ ko hoạt động do hệ thống DNS\ncủa ph\xeda domain của bạn. Bạn h\xe3y chuyển sang table\n<span>https:// buy</span> để tự setup bằng tay."
            },
            "odd.title": "Danh s\xe1ch {{value}} domain",
            "even.title": "Danh s\xe1ch {{value}} domains",
            "unpublished": {
                "odd.title": "Danh s\xe1ch {{value}} domain ẩn",
                "even.title": "Danh s\xe1ch {{value}} domains ẩn"
            }
        }
    }
};
