import countries from "@gqlapp/config/country/countries";
import opt from "@gqlapp/base/utils/opt";
class Profile {
    set name(value) {
        this._name = value;
    }
    get name() {
        return this._name || "";
    }
    constructor({ name , first_name , last_name , middle  }){
        this._name = "";
        this.firstName = "";
        this.lastName = "";
        this.middle = "";
        this.name = name;
        this.firstName = first_name;
        this.lastName = last_name;
        this.middle = middle;
    }
}
class Address {
    toString() {
        let address = [];
        if (this.address) address.push(this.address);
        if (this.address_2) address.push(this.address_2);
        if (this.locality) address.push(this.locality);
        if (this.dependentlocality) address.push(this.dependentlocality);
        if (this.zone) address.push(this.zone);
        if (this.country) {
            let { label: country  } = opt(countries, this.country);
            address.push(country.t());
        }
        if (this.zipcode) address.push(this.zipcode);
        return address.join(", ");
    }
    constructor({ country , zone , locality , dependent_locality , address , address_2 , zipcode  }){
        this.country = country;
        this.zone = zone;
        this.locality = locality;
        this.dependentlocality = dependent_locality;
        this.address = address;
        this.address_2 = address_2;
        this.zipcode = zipcode;
    }
}
class Auth {
    toArray() {
        var ref, ref1;
        let $fields = [];
        if ((ref = this.facebook) === null || ref === void 0 ? void 0 : ref.status) {
            $fields.push({
                ...this.facebook,
                type: "facebook"
            });
        }
        if ((ref1 = this.google) === null || ref1 === void 0 ? void 0 : ref1.status) {
            $fields.push({
                ...this.google,
                type: "google"
            });
        }
        return $fields;
    }
    constructor({ facebook , google  }){
        this.facebook = {};
        this.google = {};
        this.facebook = facebook || {};
        this.google = google || {};
    }
}
export class User {
    /**
   * instanse
   */ static instanse(data) {
        return new this().save(data || {});
    }
    get address() {
        return this._address;
    }
    set address(address) {
        this._address = new Address(address || {});
    }
    /**
   * profile
   */ get profile() {
        return this._profile;
    }
    set profile(profile) {
        this._profile = new Profile(profile || {});
    }
    get auth() {
        return this._auth;
    }
    set auth(auth) {
        this._auth = new Auth(auth || {});
    }
    get display_name() {
        return this.profile.name || "";
    }
    set phone(value) {
        try {
            value = value.replace(/\D/g, "");
        } catch (e) {}
        try {
            let { phone: code , value: area  } = [
                ...countries
            ].filter((param)=>{
                let { phone: code  } = param;
                return new RegExp("^".concat(code)).test(value.replace(/\D/g, ""));
            }).shift();
            value = value.replace(new RegExp("^".concat(code)), "");
            this._area = area;
        } catch (e1) {}
        this._phone = value;
    }
    set area(value) {
        this._area = value || "VN";
    }
    get area() {
        return this._area || "VN";
    }
    get phone() {
        return this._phone || "";
    }
    get roles() {
        return this._roles;
    }
    set roles(roles) {
        const rest = [];
        roles = typeof roles === "string" ? [
            roles
        ] : roles || [];
        roles.map((role)=>{
            if (!rest.includes(role)) {
                rest.push(role);
            }
        });
        this._roles = rest;
    }
    reset() {
        // tslint:disable-next-line:no-this-assignment
        const $self = this;
        Object.assign($self, User.instanse());
    }
    // @ts-ignore
    save(props) {
        let { id , image , orderRole , photo , status , name , birthday , sex , username , domainID  } = props;
        let { role , roles , perms , access , mail , phone , area , address , profile , ga , pixel , auth  } = props;
        this.ga = ga;
        this.pixel = pixel;
        this.orderRole = orderRole;
        this.id = id;
        this.mail = mail;
        this.name = name;
        this.username = username;
        this.status = !!status;
        this.sex = sex || "other";
        this.birthday = birthday;
        this.domainID = domainID || 0;
        this.area = area;
        this.phone = phone;
        this.role = role || "anonymous";
        this.roles = roles || [
            "anonymous"
        ];
        this.auth = auth;
        this.address = address;
        this.profile = {
            ...profile,
            name
        };
        this.photo = photo;
        this.image = image;
        this.perms = perms;
        this.access = access;
        return this;
    }
    hasPerms(perms) {
        const $perms = this.perms || {};
        perms = typeof perms === "string" ? [
            perms
        ] : perms || [];
        return !!perms.filter((perm)=>$perms[perm]).length;
    }
    hasRole(roles) {
        const $roles = this.roles;
        roles = typeof roles === "string" ? [
            roles
        ] : roles || [];
        return !!roles.filter((role)=>$roles.includes(role)).length;
    }
    constructor(){
        this.refetch = null;
        this.fetchMore = null;
        this.subscribeToMore = null;
        this.domainID = 0;
        this._roles = [];
        this.role = null;
        this._address = new Address({});
        this._profile = new Profile({});
        this._auth = new Auth({});
    }
}
export default User;
