const processMenu = function(menus, param) {
    let { name , title , url , onClick , ...val } = param, props = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    name = name || title;
    url && url.path && (url = url.path);
    Object.assign(val, {
        name,
        title,
        onClick,
        url
    });
    if (typeof onClick === "function") {
        Object.assign(val, {
            url: "#",
            onClick: (e)=>onClick(e, props)
        });
    } else if (typeof onClick === "string" && onClick === "toggle") {
        Object.assign(val, {
            url: "#",
            onClick: (e)=>{
                e.preventDefault();
                try {
                    var ref;
                    props === null || props === void 0 ? void 0 : (ref = props.siteAction) === null || ref === void 0 ? void 0 : ref.callback();
                } catch (e1) {
                    error(e1);
                }
            }
        });
    }
    menus.add(val);
};
export default processMenu;
