import React from "react";
import loadable from "next/dynamic";
const LockedContainer = loadable(()=>import("./Locked").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Locked")
            ]
    }
});
export default LockedContainer;
