import { graphql } from "react-apollo";
import CUSTOM_PAGES_DELETE from "../graphql/CustomPagesDelete.graphql";
import loading from "@gqlapp/base/utils/loading";
export const withCustomPagesDeleting = graphql(CUSTOM_PAGES_DELETE, {
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            customPagesDelete: async (variables)=>loading(loadbar, async ()=>mutate({
                        variables
                    }))
        };
    }
});
export default withCustomPagesDeleting;
