import Domains from "@xem/domain-common/classes/Domains";
import QueryLoading from "@xem/entity/classes/QueryLoading";
import DOMAINS_QUERY from "@xem/domain-common/graphql/domainsQuery.graphql";
function domainSearch(props) {
    let { setFieldValue , loadbar , userSubscribeToMore  } = props;
    let domains = Domains.instanse({
        filter: {
            type: [
                "ns"
            ],
            get_default: true,
            public: true
        }
    });
    return async function() {
        setFieldValue("pageLoading", true);
        domains.update(await QueryLoading(loadbar, userSubscribeToMore, DOMAINS_QUERY)(domains.variables));
        setFieldValue("pageLoading", false);
        return domains;
    };
}
export function domainCallback(props) {
    let { onActions  } = props || {};
    let search = domainSearch(props);
    return async function(rest) {
        let options = [], domains = await search();
        domains.items.map((domain)=>{
            options.push({
                value: domain.id,
                label: domain.full
            });
        });
        onActions["domain.search"] && onActions["domain.search"]({
            ...rest,
            options
        });
    };
}
