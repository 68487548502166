import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import LINKS_QUERY from "@xem/link-common/graphql/linksQuery.graphql";
import Entities from "@xem/link-common/classes/Links";
import withLinks from "@xem/link-common/classes/withLinks";
import withLinksDeleting from "@xem/link-common/classes/withLinksDeleting";
import withLinksUpdate from "@xem/link-common/classes/withLinksUpdate";
import onDeletes from "./utils/onDeletes";
import onUpdates from "./utils/onUpdates";
import { getFilterByKey } from "./Filter";
import LinkTools from "./CpanelMediaTools";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const LinksCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        const getEntities = function getPath(param) {
            let { query , cookie , status ="active"  } = param;
            let page = (query === null || query === void 0 ? void 0 : query.page) || 0, id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CREATED",
                protected: true
            });
            let orderBy, direction;
            let filter = {};
            cookie && ({ orderBy , direction , status  } = getFilterByKey(cookie));
            !!id && Object.assign(filter, {
                tids: [
                    id
                ]
            });
            !!status && Object.assign(filter, {
                status
            });
            !!orderBy && (entities.orderBy = orderBy);
            !!direction && (entities.direction = direction);
            entities.filter = filter;
            entities.page = parseInt("".concat(page));
            // entities.notOwnDomain = true;
            // entities.ownDomain = true;
            return entities;
        };
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(LinkTools, withProps(input), withLinksDeleting, withLinksUpdate, withProps((param)=>{
            let { linksDelete , linksUpdate , query , cookie , ...props } = param;
            var ref;
            return {
                onDeletes: onDeletes(linksDelete, props),
                onUpdates: onUpdates(linksUpdate, props),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), ...withPropsBefore || [], withLinks((param)=>{
            let { entities  } = param;
            return {
                links: entities,
                entitiesReset: true
            };
        }), ...withPropsInput || [], withLoading)(Route);
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { req , res , path , query , apolloClient  } = param;
            let ctx = {}, variables = {};
            let cookie = getCookie("link_list_sort", {
                req,
                res
            });
            if (cookie) {
                Object.assign(ctx, {
                    cookie: {
                        filter: {
                            key: cookie
                        }
                    }
                });
            }
            let entities = getEntities({
                path,
                query,
                cookie
            });
            variables = entities.variables;
            /** Preload taxonomy **/ let result = await apolloClient.query({
                query: LINKS_QUERY,
                variables,
                fetchPolicy: "no-cache"
            });
            /** set cache taxonomy **/ await apolloClient.writeQuery({
                query: LINKS_QUERY,
                variables,
                data: result === null || result === void 0 ? void 0 : result.data
            });
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
};
export default LinksCompose;
