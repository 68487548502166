import { graphql } from "react-apollo";
import CURRENT_USER_QUERY from "../graphql/CurrentUserQuery.graphql";
/**
 *
 */ export default graphql(CURRENT_USER_QUERY, {
    skip: (param)=>{
        let { currentUserSkip: skip  } = param;
        return skip;
    },
    props (param) {
        let { ownProps: { currentUser  } , data: { loading , currentUser: user , session , refetch , fetchMore , subscribeToMore  }  } = param;
        currentUser.refetch = refetch;
        currentUser.fetchMore = fetchMore;
        currentUser.subscribeToMore = subscribeToMore;
        if (!loading && currentUser && user) {
            currentUser.save(user || {});
        }
        return {
            session,
            loading,
            currentUserLoading: loading,
            currentUser,
            refetchCurrentUser: refetch,
            userSubscribeToMore: subscribeToMore
        };
    }
});
