export default {
    load: (props)=>{
        let webType = (props === null || props === void 0 ? void 0 : props.webType) || "web";
        let isDisableSwipeable = !!(props === null || props === void 0 ? void 0 : props.isDisableSwipeable);
        let modalProps = {
            webType,
            onSwipedDown: function(param) {
                let { to , findSnap , onClose  } = param;
                if (typeof findSnap === "function") {
                    to = findSnap(to);
                    if (to === 0) {
                        // @ts-ignore
                        onClose && onClose();
                    }
                }
            },
            defaultSnap: (param)=>{
                let { maxHeight  } = param;
                return maxHeight;
            },
            snapPoints: (param)=>{
                let { maxHeight  } = param;
                let points = [
                    0
                ];
                if (maxHeight) {
                    points.push(maxHeight);
                }
                return points;
            }
        };
        if (!isMobile() || isDisableSwipeable) {
            Object.assign(modalProps, {
                disableHeaderSwipeable: true,
                disableBodySwipeable: true,
                disableFooterSwipeable: true
            });
        }
        if (webType === "web") {
            Object.assign(modalProps, {
                webType,
                defaultSnap: ()=>null,
                snapPoints: ()=>[
                        0
                    ]
            });
        }
        return modalProps;
    }
};
