"use strict";
module.exports = {
    "en": {
        "webrisks": {
            "title": "Webrisk list"
        },
        "label": {
            "webrisks": {
                "filter": "Filter risky websites"
            }
        },
        "breadcrumb": {
            "title": "Webrisk list"
        },
        "validation": {
            "brand": {
                "required": "Please enter brand webrisk",
                "min": "Brand domain names must have at least {{value}} characters."
            },
            "illegal": "Invalid domain name."
        },
        "form": {
            "placeholder": {
                "path": "Block path"
            },
            "title": {
                "display": "Display",
                "delete": "Delete risky website"
            }
        },
        "views": {
            "az": "Order from A to Z",
            "za": "Order from Z to A"
        },
        "list": {
            "title": "Webrisk list",
            "btn": {
                "add": "Add brand webrisk",
                "edit": "Edit webrisk",
                "connect": "Add Root Domain",
                "delete": "Delete webrisk"
            },
            "column": {
                "name": "Name",
                "scheme": "URL scheme",
                "blocked": "Locked",
                "default": "Default",
                "home_site": "Forward home page",
                "active": "Verified",
                "pending": "Verifying",
                "inactive": "Not verified",
                "hostname": "Brand domain name",
                "status": "Status",
                "connect": "Root domain",
                "created": "Creation date",
                "actions": ""
            }
        },
        "notify": {
            "created": "Risk site added.",
            "odd": {
                "title": "List of {{value}} webrisk"
            },
            "even": {
                "title": "List of {{value}} webrisks"
            },
            "unpublished": {
                "odd": {
                    "title": "List of hidden webrisk {{value}}"
                },
                "even": {
                    "title": "List of {{value}} hidden webrisks"
                }
            }
        }
    },
    "vi": {
        "webrisks": {
            "title": "Danh s\xe1ch webrisk"
        },
        "label": {
            "webrisks": {
                "filter": "Lọc web rủi ro"
            }
        },
        "breadcrumb": {
            "title": "Danh s\xe1ch webrisk"
        },
        "validation": {
            "brand": {
                "required": "H\xe3y nhập webrisk thương hiệu",
                "min": "T\xean miền thương hiệu phải c\xf3 \xedt nhất {{value}} k\xfd tự."
            },
            "illegal": "T\xean miền kh\xf4ng hợp lệ."
        },
        "form": {
            "placeholder": {
                "path": "Đường dẫn block"
            },
            "title": {
                "display": "Hiển thị",
                "delete": "Xo\xe1 web rủi do"
            }
        },
        "views": {
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "list": {
            "title": "Danh s\xe1ch webrisk",
            "btn": {
                "add": "Th\xeam webrisk thương hiệu",
                "edit": "Chỉnh sửa webrisk",
                "connect": "Th\xeam T\xean Miền Gốc",
                "delete": "Xo\xe1 webrisk"
            },
            "column": {
                "name": "T\xean",
                "scheme": "URL scheme",
                "blocked": "Đang kho\xe1",
                "default": "Mặc định",
                "home_site": "Chuyển tiếp trang chủ",
                "active": "Đ\xe3 x\xe1c minh",
                "pending": "Đang x\xe1c minh",
                "inactive": "Chưa x\xe1c minh",
                "hostname": "T\xean miền thương hiệu",
                "status": "Trạng th\xe1i",
                "connect": "T\xean miền gốc",
                "created": "Ng\xe0y khởi tạo",
                "actions": ""
            }
        },
        "notify": {
            "created": "Đ\xe3 th\xeam web rủi ro.",
            "odd": {
                "title": "Danh s\xe1ch {{value}} webrisk"
            },
            "even": {
                "title": "Danh s\xe1ch {{value}} webrisks"
            },
            "unpublished": {
                "odd": {
                    "title": "Danh s\xe1ch {{value}} webrisk ẩn"
                },
                "even": {
                    "title": "Danh s\xe1ch {{value}} webrisks ẩn"
                }
            }
        }
    }
};
