import withProps from "recompose/withProps";
export const Style = withProps({
    sx: {
        backgroundColor: "#f2f2f2",
        "&.mui-custompage-table": {
            backgroundColor: "#ffffff",
            padding: "0",
            "& th": {
                "&.MuiTableCell-root": {
                    height: "40px"
                }
            },
            "& td, & th": {
                borderBottom: "1px solid #f2f2f2",
                "&": {
                    padding: "0px !important",
                    paddingLeft: "0px",
                    paddingRight: "0px"
                },
                "&.MuiTableCell-root": {
                    lineHeight: "15px",
                    "&:first-of-type": {
                        paddingLeft: "0px !important"
                    },
                    "&:last-of-type": {
                        paddingRight: "0px !important"
                    }
                }
            },
            "& tr:last-of-type": {
                "& td": {
                    border: "none !important"
                }
            },
            "&.custompages-grid": {
                padding: "0px !important",
                "& td, & th": {
                    "&": {
                        padding: "5px 0 !important"
                    }
                },
                "& .item-wrap-1": {
                    marginLeft: "10px",
                    maxWidth: "170px"
                },
                "& .item-wrap-2": {
                    maxWidth: "calc(100% - 180px)"
                },
                "& .custompage-views": {
                    fontSize: "12px",
                    backgroundColor: "#212121"
                },
                "& .custompage-title": {
                    lineHeight: "22px !important",
                    color: "#212121"
                },
                "& .custompage-href": {
                    marginTop: "5px",
                    fontSize: "12px",
                    lineHeight: "14px !important"
                },
                "& tr:last-of-type": {
                    "& td": {
                        border: "none !important"
                    }
                }
            }
        }
    }
});
export default Style;
