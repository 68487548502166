export class Webrisk {
    static instanse(data) {
        return new this().save(data || {});
    }
    /**
   *
   * @param id
   * @param path
   * @param weight
   * @param type
   * @param created
   */ save(param) {
        let { id , path , weight , type , created  } = param;
        this._id = id;
        this._path = path;
        this._weight = weight;
        this._created = created;
        this._type = type;
        return this;
    }
    get id() {
        return this._id || 0;
    }
    get path() {
        return this._path || "";
    }
    get type() {
        return this._type;
    }
    get weight() {
        return this._weight || 0;
    }
    get created() {
        return this._created * 1000 || null;
    }
}
export default Webrisk;
