import saveStatus from "@gqlapp/base/utils/saveStatus";
/**
 *
 * @param action
 * @param errorMsg
 */ export const onAction = function(action) {
    let { errorMsg  } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return async (input)=>{
        let values = await action(input);
        // @ts-ignore
        return saveStatus({
            values,
            errorMsg
        });
    };
};
export default onAction;
