export const getPreview = (preview)=>{
    let [width, height] = "".concat(preview).split(":").map((v)=>parseInt(v, 10));
    width = Math.round(width);
    height = Math.round(height);
    return [
        width,
        height
    ];
};
export const getRatio = (param)=>{
    let { preview ="0:0" , baseWidth , baseHeight , width , height  } = param;
    let [pw, ph] = getPreview(preview);
    width = width || baseWidth;
    height = height || baseHeight;
    if (!!width && !!height) {
        const ratioWidth = width / pw;
        const ratioHeight = height / ph;
        if (ratioWidth <= ratioHeight) {
            width = pw;
            height = height / ratioWidth;
        } else {
            width = width / ratioHeight;
            height = ph;
        }
    }
    return [
        width,
        height
    ];
};
export const zoomTo = function() {
    let preview = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "0:0", width = arguments.length > 1 ? arguments[1] : void 0, height = arguments.length > 2 ? arguments[2] : void 0;
    let [pw, ph] = getPreview(preview);
    let newWidth = 0, newHeight = 0;
    if (!!width && !!height) {
        const ratioWidth = width / pw;
        const ratioHeight = height / ph;
        if (ratioWidth * pw > width || ratioWidth * ph > height) {
            newWidth = pw * ratioHeight;
            newHeight = ph * ratioHeight;
        } else {
            newWidth = pw * ratioWidth;
            newHeight = ph * ratioWidth;
        }
    }
    newWidth = newWidth || 0;
    newHeight = newHeight || 0;
    return [
        newWidth,
        newHeight
    ];
};
const getMaxMin = (size, preview)=>{
    const max = Math.round((size - preview) / 2);
    const min = -max;
    let pecentMin = 50 - max / size * 100;
    let pecentMax = 50 + max / size * 100;
    return [
        max,
        min,
        pecentMax,
        pecentMin
    ];
};
export const getAttribute = (preview, width, height)=>{
    let [previewWidth, previewHeight] = getPreview(preview);
    let [maxX, minX, pMaxX, pMinX] = getMaxMin(width, previewWidth);
    let [maxY, minY, pMaxY, pMinY] = getMaxMin(height, previewHeight);
    return [
        [
            maxX,
            minX,
            maxY,
            minY
        ],
        [
            pMaxX,
            pMinX,
            pMaxY,
            pMinY
        ]
    ];
};
export function clamp(val, min, max) {
    return val < min ? min : val > max ? max : val;
}
export function getPoint(param) {
    let { x , y , width , height  } = param;
    return {
        pointX: width * (50 - x) / 100,
        pointY: height * (50 - y) / 100
    };
}
function getPos(pos, min, max) {
    if (pos > max) {
        pos = max;
    } else if (pos < min) {
        pos = min;
    }
    return pos;
}
export function clamped(param) {
    let { x , y , preview , width , height , ...props } = param;
    const [[], [maxX, minX, maxY, minY]] = getAttribute(preview, width, height);
    x = getPos(x, minX, maxX);
    y = getPos(y, minY, maxY);
    return {
        ...props,
        x,
        y,
        preview,
        width,
        height
    };
}
