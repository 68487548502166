import { graphql } from "react-apollo";
import mapInput from "@gqlapp/base/utils/mapInput";
import removeTypename from "@gqlapp/base/utils/removeTypename";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import GENS_QUERY from "../graphql/gensQuery.graphql";
const withGens = (input)=>compose(withProps((props)=>mapInput(input, props)), graphql(GENS_QUERY, {
        options: (param)=>{
            let { page , pageSize , orderBy , direction , filter  } = param;
            return {
                variables: {
                    page,
                    pageSize,
                    orderBy,
                    direction,
                    filter
                }
            };
        },
        props (param) {
            let { ownProps: { gens  } , data: { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  }  } = param;
            if (!loading && res) {
                gens.update(removeTypename(res));
            }
            return {
                loading,
                gensRefetch: refetch,
                gensMore: fetchMore,
                gensSubscribeToMore: subscribeToMore,
                updateQuery,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
export default withGens;
export { GENS_QUERY };
