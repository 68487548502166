import compose from "recompose/compose";
import withProps from "recompose/withProps";
let seed = 0;
let idNum = 0;
export function rand(min, max) {
    seed = (seed * 9301 + 49297) % 233280;
    return min + seed / 233280.0 * (max - min);
}
export function setIdNum(value) {
    idNum = value;
}
export function getIdNum() {
    idNum += 1;
    return idNum.toString();
}
export function fillEmptyWith(arr, value) {
    for(let i = 0; i < arr.length; i++)if (!arr[i]) arr[i] = value;
    return arr;
}
export function outerHtml(selectedIndex) {
    try {
        return document.getElementsByClassName("Qr-item-svg")[selectedIndex].outerHTML;
    } catch (e) {
        console.warn(e);
    }
}
export function getExactValue(value, defaultValue) {
    if (typeof value != "string") return value;
    if (value.length <= 0) value = defaultValue;
    if (!isNaN(value)) value = parseInt(value);
    return value;
}
export function extend(target, options) {
    for(let name in options){
        target[name] = options[name];
    }
    return target;
}
function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);
    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);
    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);
    return scrollbarWidth;
}
export function setScrollbarWidthProp() {
    document.documentElement.style.setProperty("--scrollbar-width", getScrollbarWidth() + "px");
}
const hexToRgb = function(hex) {
    let opacity = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    try {
        return (hex = hex.replace("#", "")).match(new RegExp("(.{" + hex.length / 3 + "})", "g")).map(function(l) {
            return parseInt(hex.length % 2 ? l + l : l, 16);
        });
    } catch (e) {
        return [
            0,
            0,
            0
        ];
    }
};
const hexToRgbTo = function(hex) {
    let opacity = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    const [r, g, b] = hexToRgb(hex, opacity);
    return 0.299 * r + 0.587 * g + 0.114 * b;
};
const brighterToRgb = function(hex) {
    let bright = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1.5, opacity = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1;
    let [r, g, b] = hexToRgb(hex, opacity);
    r = Math.min(255, r * bright);
    g = Math.min(255, g * bright);
    b = Math.min(255, b * bright);
    return "rgb(".concat(r, ",").concat(g, ",").concat(b, ", ").concat(opacity, ")");
};
export const withFrameProcess = function() {
    return compose(...arguments, withProps((param)=>{
        let { svg: { frameBody  }  } = param;
        return {
            rgb: hexToRgbTo(frameBody)
        };
    }), withProps((param)=>{
        let { svg , frameBody , rgb  } = param;
        return {
            svg: {
                ...svg,
                textColor: rgb < 180 ? "#ffffff" : "#000000",
                brighter: brighterToRgb(frameBody),
                shadowDarker: rgb < 180 ? "#000000" : "#ffffff",
                shadowDark: rgb < 180 ? "#000000" : "#ffffff",
                outline: rgb < 100 ? "#ffffff" : "#000000",
                color: frameBody
            }
        };
    }), withProps((param)=>{
        let { svg: { metrics , metrics: { width , height  } , ...svg }  } = param;
        return {
            svg: {
                ...svg,
                metrics: {
                    ...metrics,
                    width: width || 1,
                    height: height || 1
                }
            }
        };
    }), withProps((param)=>{
        let { svg: { metrics , metrics: { width , height  } , ...svg }  } = param;
        return {
            svg: {
                ...svg,
                metrics,
                scale: 1000 / (width || 1),
                viewBox: "0 0 1000 ".concat(height * (1000 / width))
            }
        };
    }));
};
