import Entities from "@gqlapp/core-common/Entities";
import User from "./User";
import usersMore from "./usersMore";
class Users extends Entities {
    set fetchMore(value) {
        this._fetchMore = value;
    }
    get fetchMore() {
        return usersMore(this._fetchMore);
    }
    /**
   *
   */ static instanse() {
        let props = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : null;
        return new this(props).save([]);
    }
    constructor(props = {}){
        let { orderBy , ...rest } = props || {};
        super({
            ...rest,
            orderBy: orderBy || "CHANGED"
        });
        this._fetchMore = null;
        this.subscribeToMore = null;
        this.updateQuery = null;
        this.interface = User;
    }
}
export default Users;
