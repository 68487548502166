const CERTIFICATE_DEVSERIAL = "00";
export default {
    secret: "production" === "test" ? "secret for tests" : process.env.AUTH_SECRET,
    serial: {
        enabled: false
    },
    session: {
        enabled: true
    },
    jwt: {
        enabled: false,
        tokenExpiresIn: "1m",
        refreshTokenExpiresIn: "7d"
    },
    password: {
        requireEmailConfirmation: true,
        sendPasswordChangesEmail: true,
        minLength: 6,
        enabled: true
    },
    certificate: {
        devSerial: CERTIFICATE_DEVSERIAL,
        enabled: false
    },
    social: {
        facebook: {
            enabled: true,
            clientID: process.env.FACEBOOK_CLIENTID,
            clientSecret: process.env.FACEBOOK_CLIENTSECRET,
            callbackURL: "/auth/facebook/callback.html",
            scope: [
                "email"
            ],
            profileFields: [
                "id",
                "emails",
                "displayName"
            ]
        },
        github: {
            enabled: false,
            clientID: process.env.GITHUB_CLIENTID,
            clientSecret: process.env.GITHUB_CLIENTSECRET,
            callbackURL: "/auth/github/callback",
            scope: [
                "user:email"
            ]
        },
        linkedin: {
            enabled: false,
            clientID: process.env.LINKEDIN_CLIENTID,
            clientSecret: process.env.LINKEDIN_CLIENTSECRET,
            callbackURL: "/auth/linkedin/callback",
            scope: [
                "r_emailaddress",
                "r_basicprofile"
            ]
        },
        google: {
            enabled: true,
            clientID: process.env.GOOGLE_CLIENTID,
            clientSecret: process.env.GOOGLE_CLIENTSECRET,
            callbackURL: "/auth/google/callback.html",
            scope: [
                "https://www.googleapis.com/auth/userinfo.email",
                "https://www.googleapis.com/auth/userinfo.profile"
            ]
        }
    }
};
