"use strict";
module.exports = {
    "en": {
        "title": "",
        "breadcrumb": {
            "list": "List of members",
            "profile": "Account information"
        },
        "list": {
            "btn": {
                "avatar": "Change avatar",
                "profile": "Change Member Information",
                "role": "Edit permissions",
                "settings": "Edit permissions",
                "block": "Account lock",
                "unblock": "Unlock account",
                "delete": "Delete account"
            }
        },
        "home": "Home",
        "login": "Login",
        "register": "Register",
        "forgotPassword": "Recover Password",
        "free-register": "Register for free",
        "logout": "Log out",
        "next": "Next",
        "verification": "Verification",
        "status": {
            "active": "Active",
            "inactive": "Inactive"
        },
        "resetPass": {
            "title": "Regenerate New Password",
            "meta": "Forgot password page",
            "errorMsg": "Password reset failed!",
            "form": {
                "title": "Regenerate New Password",
                "msg": "Use {{value}} or more characters and combine letters, numbers and symbols",
                "fldEmail": "Email",
                "btnSubmit": "Completed",
                "field": {
                    "pass": "New password",
                    "passConf": "Re-enter password"
                }
            }
        },
        "pass": {
            "title": "Recover Password",
            "meta": "Forgot password page",
            "errorMsg": "Password reset failed!",
            "form": {
                "submitMsg": "Instructions for resetting your password have been emailed to you.",
                "title": "Recover Password",
                "fldEmail": "Email",
                "field": {
                    "name": "EMail address"
                },
                "btnSubmit": "Recover Password"
            }
        },
        "label": {
            "add": "Add member",
            "list": "List of members",
            "login": "Login",
            "register": "Register",
            "profile": "Account",
            "free-register": "Register for free",
            "forgotPass": "Find password",
            "reset": "Reset password",
            "logout": "Log out",
            "inbox": "Mailbox",
            "contact": "Contact"
        },
        "role": {
            "label": "Permissions",
            "all": "All",
            "anonymous": "Guest",
            "authenticated": "Member",
            "root": "Administrator",
            "administrator": "Senior administrator",
            "admin": "Admin",
            "moderator": "Administrator",
            "host": "Host",
            "business": "Business",
            "professional": "Professional",
            "sale": "Promotion"
        },
        "users": {
            "title": "Member list",
            "column": {
                "name": "Account name",
                "roles": "Decentralization",
                "email": "Email",
                "role": "Role",
                "active": "Active",
                "actions": "Actions"
            },
            "btn": {
                "add": "Add member"
            },
            "list": {
                "title": "Member list"
            }
        },
        "update": {
            "saved": "Update successful",
            "errorMsg": "Unknown error"
        },
        "popup": {
            "area": "Select area",
            "name": "Change first and last name",
            "info": "Account",
            "password": "Change password",
            "mail": "Change email",
            "address": "Edit contact",
            "birthday": "Change date of birth",
            "phone": "Change phone number",
            "roles": "Decentralization",
            "confirm": "Enter verification code",
            "avatar": "Change avatar",
            "country": "Country"
        },
        "userEdit": {
            "form": {
                "sex": {
                    "other": "Other",
                    "female": "Female",
                    "male": "Male"
                },
                "name": {
                    "firstname": "First name",
                    "lastname": "Last name",
                    "middle": "Middle name"
                },
                "password": {
                    "old": "Old password",
                    "new": "New password",
                    "confirm": "Re-enter password"
                },
                "address": {
                    "address": "Address",
                    "address2": "Address 2",
                    "city": "City",
                    "state": "State - Province",
                    "zipcode": "Area code",
                    "country": "Country"
                },
                "birthday": {
                    "day": "Day",
                    "month": "Month",
                    "year": "Year"
                },
                "field": {
                    "codeConfirm": "{{sitename}} will send a text message including a 6-digit <br /> verification code. Enter your phone number.",
                    "keyword": "Search",
                    "display": "Full name",
                    "address": "Address",
                    "female": "Female",
                    "male": "Male",
                    "other": "Other",
                    "displayName": "Full name",
                    "firstName": "Name",
                    "lastName": "Last name and middle name",
                    "sex": "Gender",
                    "birthday": "Date of birth",
                    "day": "Day",
                    "month": "Month",
                    "year": "Year",
                    "phone": "Phone number",
                    "currentPassword": "Current Password",
                    "pass": "Password",
                    "password": "Current password",
                    "new_password": "New password",
                    "Confirm_password": "Re-enter password",
                    "username": "Login name",
                    "mail": "Email",
                    "active": "Active",
                    "subscribe": "Update me about promotions and news related to {{value}}"
                }
            }
        },
        "profile": {
            "label": "Account",
            "title": "Account information",
            "breadcrumb": "Account information",
            "headerText": "Account information",
            "meta": "Account information page",
            "loadMsg": "Loading...",
            "errorMsg": "No current user is logged in",
            "editProfileText": "Edit profile",
            "company": {
                "pattern": "Pattern number",
                "serial": "Symbol",
                "name": "Company name",
                "code": "Tax code",
                "address": "Address",
                "phone": "Phone",
                "fax": "Fax Number",
                "mail": "Email",
                "represent": "Representative",
                "bank_no": "Account number",
                "bank_name": "Bank name",
                "account_holder": "Account holder"
            },
            "form": {
                "field": {
                    "female": "Female",
                    "male": "Male",
                    "other": "Other",
                    "displayName": "Full name",
                    "firstName": "Name",
                    "lastName": "Last name and middle name",
                    "sex": "Gender",
                    "birthday": "Date of birth",
                    "day": "Day",
                    "month": "Month",
                    "year": "Year",
                    "phone": "Phone",
                    "password": "Current password",
                    "new_password": "New password",
                    "Confirm_password": "Re-enter password",
                    "username": "Login name",
                    "mail": "Email",
                    "subscribe": "Update me about promotions and news related to {{value}}"
                }
            },
            "btn": {
                "save": "Save changes",
                "fbBtn": "Connect to Facebook account",
                "googleBtn": "Connect to Google account",
                "githubBtn": "Connect to github account",
                "linkedinBtn": "Connect to a linkedin account"
            }
        },
        "notify": {
            "delete": 'Are you sure you will delete the member<br /><span style="color: #006621">{{value}}</span> ?',
            "block": 'Are you sure you will block members<br /><span style="color: #006621">{{value}}</span> ?',
            "unblock": 'Are you sure you will unlock the membership<br /><span style="color: #006621">{{value}}</span> ?',
            "odd": {
                "title": "List of {{value}} users"
            },
            "even": {
                "title": "List of {{value}} users"
            },
            "unpublished": {
                "odd": {
                    "title": "List of {{value}} hidden users"
                },
                "even": {
                    "title": "List of hidden {{value}} users"
                }
            }
        }
    },
    "global": {
        "Start a {{value}}-Day Free Trial": "D\xf9ng thử {{value}} ng\xe0y miễn ph\xed"
    },
    "vi": {
        "title": "",
        "breadcrumb": {
            "list": "Danh s\xe1ch th\xe0nh vi\xean",
            "profile": "Th\xf4ng tin t\xe0i khoản"
        },
        "list": {
            "btn": {
                "avatar": "Thay đổi avatar",
                "profile": "Thay đổi Th\xf4ng tin th\xe0nh vi\xean",
                "role": "Chỉnh sửa ph\xe2n quyền",
                "settings": "Chỉnh sửa ph\xe2n quyền",
                "block": "Kho\xe1 t\xe0i khoản",
                "unblock": "Mở kho\xe1 t\xe0i khoản",
                "delete": "Xo\xe1 t\xe0i khoản"
            }
        },
        "home": "Trang chủ",
        "login": "Đăng nhập",
        "register": "Đăng k\xfd",
        "forgotPassword": "Lấy Lại Mật Khẩu",
        "free-register": "Đăng k\xfd miễn ph\xed",
        "logout": "Đăng xuất",
        "next": "Tiếp Theo",
        "verification": "X\xe1c Minh",
        "status": {
            "active": "Đang hoạt động",
            "inactive": "Kh\xf4ng hoạt động"
        },
        "resetPass": {
            "title": "Tạo Lại Mật Khẩu Mới",
            "meta": "Trang qu\xean mật khẩu",
            "errorMsg": "Đặt lại mật khẩu kh\xf4ng th\xe0nh c\xf4ng!",
            "form": {
                "title": "Tạo Lại Mật Khẩu Mới",
                "msg": "Sử dụng {{value}} k\xfd tự trở l\xean v\xe0 kết hợp chữ c\xe1i, chữ số v\xe0 biểu tượng",
                "fldEmail": "Email",
                "btnSubmit": "Ho\xe0n Th\xe0nh",
                "field": {
                    "pass": "Mật khẩu mới",
                    "passConf": "Nhập lại mật khẩu"
                }
            }
        },
        "pass": {
            "title": "Lấy Lại Mật Khẩu",
            "meta": "Trang qu\xean mật khẩu",
            "errorMsg": "Đặt lại mật khẩu kh\xf4ng th\xe0nh c\xf4ng!",
            "form": {
                "submitMsg": "Hướng dẫn đặt lại mật khẩu của bạn đ\xe3 được gửi qua email cho bạn.",
                "title": "Lấy Lại Mật Khẩu",
                "fldEmail": "Email",
                "field": {
                    "name": "Địa chỉ EMail"
                },
                "btnSubmit": "Lấy Lại Mật Khẩu"
            }
        },
        "label": {
            "add": "Th\xeam th\xe0nh vi\xean",
            "list": "Danh s\xe1ch th\xe0nh vi\xean",
            "login": "Đăng nhập",
            "register": "Đăng k\xfd",
            "profile": "T\xe0i khoản",
            "free-register": "Đăng k\xfd miễn ph\xed",
            "forgotPass": "T\xecm mật khẩu",
            "reset": "Đặt lại mật khẩu",
            "logout": "Đăng xuất",
            "inbox": "Hộp thư",
            "contact": "Li\xean hệ",
            "users": {
                "filter": "Sắp xếp hiển thị"
            }
        },
        "role": {
            "label": "Quyền",
            "all": "Tất cả",
            "anonymous": "Kh\xe1ch",
            "authenticated": "Th\xe0nh vi\xean",
            "root": "Quản trị cấp cao",
            "administrator": "Quản trị cấp cao",
            "admin": "Quản trị",
            "moderator": "Quản trị",
            "host": "Chủ nh\xe0",
            "business": "Doanh nghiệp",
            "professional": "Chuy\xean nghiệp",
            "prob": "Tập sự",
            "sale": "Khuyến m\xe3i"
        },
        "views": {
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "users": {
            "title": "Danh s\xe1ch th\xe0nh vi\xean",
            "column": {
                "name": "T\xean t\xe0i khoản",
                "roles": "Ph\xe2n quyền",
                "email": "Email",
                "role": "Vai tr\xf2",
                "active": "Đang hoạt động",
                "actions": "H\xe0nh động"
            },
            "btn": {
                "add": "Th\xeam th\xe0nh vi\xean"
            },
            "list": {
                "title": "Danh s\xe1ch th\xe0nh vi\xean"
            }
        },
        "update": {
            "saved": "Cập nhật th\xe0nh c\xf4ng",
            "errorMsg": "Lỗi kh\xf4ng x\xe1c định"
        },
        "popup": {
            "area": "Chọn v\xf9ng",
            "name": "Thay đổi họ v\xe0 t\xean",
            "info": "T\xe0i khoản",
            "password": "Thay đổi mật khẩu",
            "mail": "Thay đổi email",
            "address": "Chỉnh sửa địa chỉ li\xean hệ",
            "birthday": "Thay đổi ng\xe0y sinh",
            "phone": "Thay đổi số điện thoại",
            "roles": "Ph\xe2n quyền",
            "confirm": "Nhập m\xe3 x\xe1c minh",
            "avatar": "Thay đổi ảnh đại diện",
            "country": "Quốc gia"
        },
        "userEdit": {
            "form": {
                "sex": {
                    "other": "Kh\xe1c",
                    "female": "Nữ",
                    "male": "Nam"
                },
                "name": {
                    "firstname": "T\xean",
                    "lastname": "Họ",
                    "middle": "T\xean đệm"
                },
                "password": {
                    "old": "Mật khẩu cũ",
                    "new": "Mật khẩu mới",
                    "confirm": "Nhập lại mật khẩu"
                },
                "address": {
                    "address": "Địa chỉ",
                    "address2": "Địa chỉ 2",
                    "city": "Th\xe0nh phố",
                    "state": "Tiểu bang - Tỉnh",
                    "zipcode": "M\xe3 v\xf9ng",
                    "country": "Quốc gia"
                },
                "birthday": {
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm"
                },
                "field": {
                    "codeConfirm": "{{sitename}} sẽ gửi một tin nhắn văn bản bao gồm m\xe3 x\xe1c minh <br /> gồm 6 chữ số. V\xe0o số điện thoại của bạn.",
                    "keyword": "T\xecm kiếm",
                    "display": "Họ v\xe0 t\xean",
                    "address": "Địa chỉ",
                    "female": "Nữ",
                    "male": "Nam",
                    "other": "Kh\xe1c",
                    "displayName": "Họ v\xe0 t\xean",
                    "firstName": "T\xean",
                    "lastName": "Họ v\xe0 t\xean đệm",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm",
                    "phone": "Số điện thoại",
                    "currentPassword": "Mật khẩu hiện tại",
                    "pass": "Mật khẩu",
                    "password": "Mật khẩu hiện tại",
                    "new_password": "Mật khẩu mới",
                    "Confirm_password": "Nhập lại mật khẩu",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử",
                    "active": "Hoạt động",
                    "subscribe": "Cập nhật cho t\xf4i về khuyến m\xe3i cũng như c\xe1c tin tức li\xean quan đến {{value}}"
                }
            }
        },
        "profile": {
            "label": "T\xe0i khoản",
            "title": "Th\xf4ng tin t\xe0i khoản",
            "breadcrumb": "Th\xf4ng tin t\xe0i khoản",
            "headerText": "Th\xf4ng tin t\xe0i khoản",
            "meta": "Trang th\xf4ng tin t\xe0i khoản",
            "loadMsg": "Loading...",
            "errorMsg": "Kh\xf4ng c\xf3 người d\xf9ng hiện tại n\xe0o đăng nhập",
            "editProfileText": "Chỉnh sửa hồ sơ",
            "company": {
                "pattern": "Mẫu số",
                "serial": "K\xfd hiệu",
                "name": "T\xean c\xf4ng ty",
                "code": "M\xe3 số thuế",
                "address": "Địa chỉ",
                "phone": "Điện thoại",
                "fax": "Số Fax",
                "mail": "Email",
                "represent": "Người đại diện",
                "bank_no": "Số t\xe0i khoản",
                "bank_name": "T\xean ng\xe2n h\xe0ng",
                "account_holder": "Chủ t\xe0i khoản"
            },
            "form": {
                "field": {
                    "female": "Nữ",
                    "male": "Nam",
                    "other": "Kh\xe1c",
                    "displayName": "Họ v\xe0 t\xean",
                    "firstName": "T\xean",
                    "lastName": "Họ v\xe0 t\xean đệm",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm",
                    "phone": "Phone",
                    "password": "Mật khẩu hiện tại",
                    "new_password": "Mật khẩu mới",
                    "Confirm_password": "Nhập lại mật khẩu",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử",
                    "subscribe": "Cập nhật cho t\xf4i về khuyến m\xe3i cũng như c\xe1c tin tức li\xean quan đến {{value}}"
                }
            },
            "btn": {
                "save": "Lưu thay đổi",
                "fbBtn": "Kết nối với t\xe0i khoản Facebook",
                "googleBtn": "Kết nối với t\xe0i khoản Google",
                "githubBtn": "Kết nối với t\xe0i khoản github",
                "linkedinBtn": "Kết nối với t\xe0i khoản linkedin"
            }
        },
        "notify": {
            "delete": 'Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 th\xe0nh vi\xean<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "block": 'Bạn c\xf3 chắc m\xecnh sẽ kho\xe1 th\xe0nh vi\xean<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "unblock": 'Bạn c\xf3 chắc m\xecnh sẽ mở kho\xe1 th\xe0nh vi\xean<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "odd": {
                "title": "Danh s\xe1ch {{value}} user"
            },
            "even": {
                "title": "Danh s\xe1ch {{value}} users"
            },
            "unpublished": {
                "odd": {
                    "title": "Danh s\xe1ch {{value}} user ẩn"
                },
                "even": {
                    "title": "Danh s\xe1ch {{value}} users ẩn"
                }
            }
        }
    }
};
