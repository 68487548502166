import Link from "./Link";
import linksMore from "@xem/entity/classes/fetchMore";
import Entities from "@gqlapp/core-common/Entities";
export class Links extends Entities {
    /**
   *
   */ static instanse(props) {
        return new this(props).save([]);
    }
    set fetchMore(value) {
        this._fetchMore = value;
    }
    get fetchMore() {
        return linksMore(this._fetchMore);
    }
    get filter() {
        if (this.ownDomain) {
            this._filter = {
                ...this._filter,
                ownDomain: this.ownDomain
            };
        }
        if (this.notOwnDomain) {
            this._filter = {
                ...this._filter,
                notOwnDomain: this.notOwnDomain
            };
        }
        return this._filter || null;
    }
    set filter(value) {
        this._filter = {
            ...this.filter,
            ...value
        };
    }
    constructor(props = {}){
        super(props);
        this.ownDomain = false;
        this.notOwnDomain = false;
        this._fetchMore = null;
        this.subscribeToMore = null;
        this.updateQuery = null;
        this.interface = Link;
    }
}
export default Links;
