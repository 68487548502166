import { graphql } from "react-apollo";
import DOMAIN_DELETE from "../graphql/domainDelete.graphql";
/**
 *
 * @param Component
 */ export const withDomainDeleting = (Component)=>graphql(DOMAIN_DELETE, {
        props: (param)=>{
            let { mutate  } = param;
            return {
                domainDelete: async (id)=>mutate({
                        variables: {
                            id
                        }
                    })
            };
        }
    })(Component);
export default withDomainDeleting;
