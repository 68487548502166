import React from "react";
import loadable from "next/dynamic";
const LoginContainer = loadable(()=>import("./Login").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./Login")
            ]
    }
});
export default LoginContainer;
