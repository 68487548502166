import { graphql } from "react-apollo";
import DOMAIN_UPDATE from "../graphql/domainUpdate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";
/**
 *
 * @param Component
 */ export const withDomainUpdate = graphql(DOMAIN_UPDATE, {
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            domainUpdate: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                        variables
                    }), (param)=>{
                        let { data  } = param;
                        return data;
                    }), (param)=>{
                        let { domainUpdate: res  } = param;
                        return res;
                    }))
        };
    }
});
export default withDomainUpdate;
