/* tslint:disable */ import Base from "@xem/entity/classes/Base";
export class Link extends Base {
    save(values) {
        super.save(values);
        const { status  } = values || {};
        this.status = !!status;
        return this;
    }
    constructor(...args){
        super(...args);
        this.status = false;
    }
}
export default Link;
