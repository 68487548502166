import ClientModule from "@gqlapp/module-client-react";
import resources from "./locales";
import Toolbars from "./helpers/toolbars";
// @ts-ignore
export default new ClientModule(Toolbars, {
    localization: [
        {
            ns: "qr",
            resources,
            more: [
                "common"
            ]
        }
    ]
});
