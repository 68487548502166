import { graphql } from "react-apollo";
import QUERY from "../graphql/PageQuery.graphql";
import mapInput from "@gqlapp/base/utils/mapInput";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
export const queryPage = graphql(QUERY, {
    skip: (param)=>{
        let { skip , path  } = param;
        return !path || skip;
    },
    options: (param)=>{
        let { path , fetchPolicy  } = param;
        return {
            variables: {
                path
            },
            fetchPolicy: fetchPolicy || "cache-and-network"
        };
    },
    props (param) {
        let { data: { loading , res , refetch , subscribeToMore , error  } , ownProps: { page  }  } = param;
        !!page && (page.refetch = refetch);
        !!page && (page.subscribeToMore = subscribeToMore);
        if (!loading && res && page) {
            page.save(res);
        }
        return {
            loading,
            errors: error ? error.graphQLErrors : null
        };
    }
});
export { QUERY as PAGE_QUERY };
export default ((input)=>compose(withProps((props)=>mapInput(input, props)), queryPage));
