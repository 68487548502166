import USERS_QUERY from "@xem/user-common/graphql/UsersSearch.graphql";
/**
 * searchTags
 * @param subscribeToMore
 * @returns {function(*=): Promise<any>}
 */ export const userSearch = (subscribeToMore)=>(param)=>{
        let { data , filter  } = param;
        return new Promise(async (resolve)=>{
            data = data || [];
            try {
                await subscribeToMore({
                    document: USERS_QUERY,
                    variables: {
                        filter
                    },
                    updateQuery: async (param, param1)=>{
                        let {} = param, { subscriptionData: { data: { contents: { edges  }  }  }  } = param1;
                        if (edges.length) {
                            edges.map((value)=>data.push(value));
                        }
                        resolve(data);
                    }
                });
            } catch (e) {
                resolve(data);
            }
        });
    };
export default userSearch;
