import React from "react";
import loadable from "next/dynamic";
const ForgotPasswordContainer = loadable(()=>import("./ForgotPassword").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./ForgotPassword")
            ]
    }
});
export default ForgotPasswordContainer;
