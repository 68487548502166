class Gen {
    /**
   * instanse
   */ static instanse() {
        return new this();
    }
    /**
   * save
   */ save(param) {
        let { id , name , note , nodes  } = param;
        this.id = id;
        this.name = name;
        this.note = note;
        this.nodes = nodes;
    }
}
export { Gen as default };
