import withProps from "recompose/withProps";
const MuiSwitch = {
    "& .MuiSwitch-custom": {
        "&.MuiFormControlLabel-root": {
            margin: 0,
            "&:not(.MuiSwitch-gray)": {
                "& .MuiSwitch-root": {
                    "& .MuiButtonBase-root": {
                        "&.Mui-checked": {
                            "&+.MuiSwitch-track": {
                                backgroundColor: "#0066CC"
                            }
                        }
                    }
                }
            },
            "& .MuiSwitch-root": {
                padding: "0",
                width: "45px",
                height: "24px",
                "& .MuiTouchRipple-root": {
                    width: "24px",
                    height: "24px"
                },
                "& .MuiButtonBase-root": {
                    padding: "3px 0",
                    margin: "0 auto",
                    marginLeft: "2px",
                    "&.Mui-checked": {
                        marginLeft: "11px",
                        transform: "translateX(12px)"
                    }
                },
                "& .MuiSwitch-track": {
                    borderRadius: "20px",
                    backgroundColor: "#c9c9c9",
                    opacity: 1
                },
                "& .MuiSwitch-thumb": {
                    backgroundColor: "#ffffff",
                    width: "18px",
                    height: "18px"
                }
            },
            "&.MuiSwitch-small": {
                "& > .MuiSwitch-root": {
                    width: "29px",
                    height: "18px",
                    "& .MuiTouchRipple-root": {
                        width: "18px",
                        height: "18px"
                    },
                    "& .MuiButtonBase-root": {
                        padding: "2px 0",
                        margin: "0 auto",
                        marginLeft: "2px",
                        "&.Mui-checked": {
                            transform: "translateX(12px)"
                        }
                    },
                    "& .MuiSwitch-track": {
                        borderRadius: "20px",
                        backgroundColor: "#f2f2f2",
                        opacity: 1
                    },
                    "& .MuiSwitch-thumb": {
                        width: "14px",
                        height: "14px"
                    }
                }
            },
            "&.MuiSwitch-big": {
                "& > .MuiSwitch-root": {
                    width: "133px",
                    height: "41px",
                    borderRadius: "5px",
                    "& .MuiSwitch-track": {
                        borderRadius: "0px",
                        zIndex: 1
                    },
                    "& .MuiButtonBase-root": {
                        padding: "0 2px",
                        height: "41px",
                        zIndex: 0,
                        "&.Mui-checked": {
                            margin: 0,
                            paddingLeft: "auto",
                            transform: "translateX(66px)"
                        },
                        "&, & .MuiSwitch-thumb": {
                            width: "66px"
                        },
                        "& .MuiSwitch-thumb": {
                            boxShadow: "none",
                            height: "34px",
                            borderRadius: "5px"
                        }
                    }
                }
            }
        }
    }
};
const ModalStyle = {
    "& .modal": {
        "&.modal-id-font, &.download, &.modal-id-gradient-type": {
            "& .modal-content": {
                "--modal-background-color": "#ffffff"
            }
        },
        "& .modal-btn-close": {
            right: "15px !important",
            "& svg": {
                width: "24px !important",
                height: "24px !important"
            }
        },
        "&.font-family, &.colorful, &.download, &.gradient": {
            "& .modal-header": {
                border: "none"
            }
        },
        "& .modal-title": {
            fontSize: "18px"
        }
    }
};
export default withProps({
    baseStyle: {
        ...MuiSwitch,
        ...ModalStyle,
        "& [data-header]": {
            "&[data-header-cpanel],&[data-header-link]": {
                zIndex: 1000,
                "& .header": {
                    "boxShadow": "0px 1px 1px rgba(176, 176, 176, 0.21)"
                }
            }
        },
        "& .MuiTabs-root": {
            lineHeight: "20px",
            minHeight: "40px",
            "& .MuiTab-root": {
                padding: 0,
                lineHeight: "20px",
                minHeight: "40px"
            }
        },
        "& .btn-tab": {
            color: "var(--btn-color,#686868)",
            "&.Mui-selected": {
                color: "var(--btn-selected-color, #0066CC)"
            }
        },
        "& .MuiTableCell-head, & .MuiTableCell-root": {
            color: "#212121"
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#0066CC",
            height: "3px"
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.5px 10px"
        },
        "& .MuiInputBase-root": {
            color: "currentColor",
            "&.MuiInputBase-adornedEnd": {
                paddingRight: "10px"
            }
        },
        "& .MuiSelect-Color-Input, & .MuiSelect-Input": {
            "& .MuiInputBase-input": {
                paddingRight: "5px"
            },
            "& .MuiSelect-Arrow-Dropdown": {
                width: "12px"
            },
            "& .MuiSelect-Color-Box": {
                width: "20px"
            }
        }
    }
});
