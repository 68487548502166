import { graphql } from "react-apollo";
import WEBRISKS_DELETE from "../graphql/webrisksDelete.graphql";
/**
 *
 * @param Component
 */ export const withWebrisksDeleting = graphql(WEBRISKS_DELETE, {
    props: (param)=>{
        let { mutate  } = param;
        return {
            webrisksDelete: async (ids)=>mutate({
                    variables: {
                        ids
                    }
                })
        };
    }
});
export default withWebrisksDeleting;
