import ClientModule from "@gqlapp/module-client-react";
import compose from "recompose/compose";
import advanced from "./advanced";
import custom from "./custom";
const popupComponents = [
    {
        component: compose(advanced, custom)
    }
];
export default new ClientModule({
    popupComponents
});
