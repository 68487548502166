import { graphql } from "react-apollo";
import PRODUCTS_DELETE from "../graphql/ProductsDelete.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";
/**
 *
 */ export const withProductsDeleting = graphql(PRODUCTS_DELETE, {
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            productsDelete: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                        variables
                    }), (param)=>{
                        let { data  } = param;
                        return data;
                    }), (param)=>{
                        let { res  } = param;
                        return res;
                    }))
        };
    }
});
export default withProductsDeleting;
