import { graphql } from "react-apollo";
import { compose, withProps } from "recompose";
import mapInput from "@gqlapp/base/utils/mapInput";
import STATS_LOCATION_QUERY from "../graphql/StatsLocation.graphql";
import statsLocationMore from "./Stats/statsLocationMore";
export const withStatsLocation = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), graphql(STATS_LOCATION_QUERY, {
            skip: (param)=>{
                let { skip  } = param;
                return !!skip;
            },
            options: function() {
                let { fetchPolicy , id , type , dateType  } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
                return {
                    fetchPolicy,
                    variables: {
                        id: id || null,
                        type,
                        dateType
                    }
                };
            },
            props (param) {
                let { data: { loading , locations , fetchMore , error  } , ownProps , ownProps: { stats  }  } = param;
                if (!loading) {
                    stats.locations = locations;
                }
                return {
                    loading,
                    statsLocationMore: statsLocationMore(stats, fetchMore, ownProps),
                    errors: error ? error.graphQLErrors : null
                };
            }
        }))(Component);
export default withStatsLocation;
