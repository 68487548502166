import Base from "@xem/entity/classes/Base";
export class CustomPage extends Base {
    /**
   * instanse
   */ static instanse(data) {
        return new this().save(data || {});
    }
    /**
   * save
   */ save(_value) {
        super.save(_value);
        let self = this;
        let { data , body , status , blocks  } = _value || {};
        let { settings  } = data || {};
        if (status === 0) {
            this.status = "inactive";
        } else if (status === -1) {
            this.status = "blocked";
        }
        self.blocks = blocks;
        self.settings = settings;
        self.body = body;
        return self;
    }
    constructor(...args){
        super(...args);
        this.status = "active";
    }
}
export default CustomPage;
