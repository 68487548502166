const replace = require("@gqlapp/base/utils/replace").default;
const formatNumber = require("@gqlapp/base/utils/formatNumber").default;

/**
 * Check https in the link time
 * @returns {""|boolean}
 */
String.prototype.isHttps = function(){
  return this.toString() && /^(https:\/\/)/i.test(this.toString());
}

String.prototype.isDomain = function(){
  const regex = /^(?!-)[0-9-\p{L}]+([\-\.]{1}[0-9\p{L}]+)*\.[0-9\p{L}]{2,6}$/u;
  return this.toString() && regex.test(this.toString());
}

/**
 * Get domain by time
 * @param subdomain
 * @returns {string}
 */
String.prototype.getDomain = function(subdomain = true) {
  let url = this.toString();
  url = `${url}`.replace(/(https?:\/\/)?(www.)?/i, '');
  if (!subdomain) {
    url = url.split('.');
    url = url.slice(url.length - 2).join('.');
  }
  if (url.indexOf('/') !== -1) {
    url = url.split('/')[0];
  }
  url = `${url}`.replace(/\:(.*)/i, '');
  return url || '';
}

/**
 * Uppercase first char title
 * @returns {string}
 */
String.prototype.title = function () {
  const sliced = this.slice(1);
  return (
    this.charAt(0).toUpperCase() +
    (sliced.toUpperCase() === sliced ? sliced.toLowerCase() : sliced)
  );
};

/**
 *
 * @returns {string}
 */
String.prototype.camelCase = function() {
  let text = `${this}`;
  text = text ? text : '';
  text = text.trim();

  return text.replace(/^([A-Z])|\s(\w)/g, function(match, p1, p2, {}) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};

/**
 * Get type by time
 * @returns {string}
 */
String.prototype.getType = function() {
  let str = `${this}`;
  str = str.trim();
  str = str.toLowerCase();

  return str.replace(/\s(\w)/g, '_');
};

/**
 * String translate
 * @returns {string}
 */
String.prototype.t = function () {
  return this.toString();
};

/**
 * String replace values
 * @returns {string}
 */
String.prototype.replaceValues = function (props) {
  try {
    return replace(this.toString(), props);
  } catch (e) {
    return this.toString();
  }
};

/**
 * $ get element
 * @returns {null|string}
 */
String.prototype.$ = function () {
  try { let e = this.toString(); return document.querySelector(e) }catch (e) {}
  return null;
}

/**
 * Number to time
 * @returns {number|string}
 */
String.prototype.numberToString = function (){
  let number = parseFloat(this);
  if (number >= 1000000000) {
    return Math.round(number / 1000000000) + "B";
  } else if (number >= 1000000) {
    return Math.round(number / 1000000) + "M";
  } else if (number >= 1000) {
    return Math.round(number / 1000) + "K";
  } else {
    return number;
  }
}

/**
 *
 * @param options
 * @returns {any}
 */
String.prototype.numberFormat = function (options){
  let number = parseFloat(`${this}`.replaceAll(',', ''));
  return formatNumber(number, options);
}
