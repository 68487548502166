import Action from "@xem/entity/classes/Action";
const webrisk = {
    list: "/webrisks.html",
    listpage: "/webrisks/:page/page.html"
};
export const map = (param)=>{
    let { id , page , path  } = param;
    return "".concat(webrisk[path]).replace(/:id/, id).replace(/:page/, page);
};
const perms = [
    "isAdmin"
];
const styleConfigs = {
    style: {
        "--button-bar-height": "50px"
    }
};
import settings from "@xem/config";
export const defaultRoute = {
    ...settings.route,
    action: Action.instanse(),
    runtimejs: true
};
export const WebriskRoute = {
    ...defaultRoute,
    ...styleConfigs,
    perms,
    exact: true,
    webType: "webrisk",
    mobile: {
        show: true
    },
    classNameOnPage: "cpanel theme-mobile"
};
export default webrisk;
