import { graphql } from "react-apollo";
import DOMAIN_BLOCK from "../graphql/domainUpdate.graphql";
/**
 *
 * @param Component
 */ export const withDomainPublic = graphql(DOMAIN_BLOCK, {
    props: (param)=>{
        let { mutate  } = param;
        return {
            domainPublic: async (id)=>mutate({
                    variables: {
                        id,
                        input: {
                            public: true
                        }
                    }
                })
        };
    }
});
export default withDomainPublic;
