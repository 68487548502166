import { graphql } from "react-apollo";
import QUERY from "../../graphql/formapi/FormApiSubmit.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";
export default graphql(QUERY, {
    props: (param)=>{
        let { mutate , ownProps: { loadbar  }  } = param;
        return {
            formSubmit: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                        variables
                    }), (param)=>{
                        let { data  } = param;
                        return data;
                    }), (param)=>{
                        let { res  } = param;
                        return res;
                    }))
        };
    }
});
