import USER_BY_EMAIL from "../graphql/UserByEmail.graphql";
/**
 * userByEmail
 * @param subscribeToMore
 */ export const userByEmail = (subscribeToMore)=>(param)=>{
        let { data , mail  } = param;
        return new Promise(async (resolve)=>{
            data = data || {};
            try {
                await subscribeToMore({
                    document: USER_BY_EMAIL,
                    variables: {
                        mail
                    },
                    updateQuery: async (param, param1)=>{
                        let {} = param, { subscriptionData: { data: { res  }  }  } = param1;
                        resolve(res);
                    }
                });
            } catch (e) {
                resolve(data);
            }
        });
    };
export default userByEmail;
