import ClientModule from "@gqlapp/module-client-react";
import resources from "./locales";
export default new ClientModule({
    localization: [
        {
            ns: "stats",
            resources,
            more: [
                "common"
            ]
        }
    ]
});
