import loading from "@gqlapp/base/utils/loading";
/**
 *
 * @param stats
 * @param fetchMore
 * @param loadbar
 */ export const statsChannelMore = (stats, fetchMore, param)=>{
    let { loadbar  } = param;
    return async (variables, bar, wait)=>new Promise(async (resolve, reject)=>loading(bar || loadbar, async ()=>{
                try {
                    await fetchMore({
                        variables,
                        updateQuery: async (_, param)=>{
                            let { fetchMoreResult: { res  }  } = param;
                            if (!wait) {
                                try {
                                    stats.channels = res;
                                } catch (e) {
                                    error(e);
                                }
                            }
                            resolve(res);
                        }
                    });
                } catch (e) {
                    error(e);
                    reject({});
                }
            }));
};
export default statsChannelMore;
