import { graphql } from "react-apollo";
import LINK_CREATE from "../graphql/linkCreate.graphql";
import map from "@gqlapp/base/utils/map";
import loading from "@gqlapp/base/utils/loading";
/**
 * Create new Link
 * @param Component
 */ export const withLinkCreate = (Component)=>graphql(LINK_CREATE, {
        props: (param)=>{
            let { mutate , ownProps: { loadbar  }  } = param;
            return {
                linkCreate: async (variables)=>loading(loadbar, async ()=>map(map(await mutate({
                            variables
                        }), (param)=>{
                            let { data  } = param;
                            return data;
                        }), (param)=>{
                            let { linkCreate: res  } = param;
                            return res;
                        }))
            };
        }
    })(Component);
export default withLinkCreate;
