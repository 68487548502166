import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import Entity from "@xem/custom-page-common/classes/CustomPage";
import withCustomPage, { CUSTOM_PAGE_QUERY } from "@xem/custom-page-common/classes/withCustomPage";
import withFormApiSubmit from "@xem/custom-page-common/classes/formapi/withFormApiSubmit";
import onAction from "@xem/entity/classes/onAction";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const ModulesCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        /**
		 * witRoute
		 */ const witRoute = compose(withProps(input), withFormApiSubmit, withProps({
            entity: Entity.instanse()
        }), withProps((param)=>{
            let { formSubmit , entity , action  } = param;
            const refetch = ()=>{
                entity && entity.refetch && entity.refetch();
            };
            action.callback = refetch;
            return {
                refetch,
                onSubmit: onAction({
                    action: formSubmit
                })
            };
        }), ...withPropsBefore || [], withCustomPage((param)=>{
            let { entity  } = param;
            return {
                customPage: entity
            };
        }), ...withPropsInput || [], withLoading)(Route);
        const setCache = async (param)=>{
            let { apolloClient , query , variables  } = param;
            /** Preload taxonomy **/ let result = await apolloClient.query({
                query,
                variables,
                fetchPolicy: "no-cache"
            });
            /** set cache taxonomy **/ await apolloClient.writeQuery({
                query,
                variables,
                data: result === null || result === void 0 ? void 0 : result.data
            });
        };
        const bindCache = async (apolloClient, query, variables)=>{
            /** set link cache*/ await setCache({
                query,
                variables,
                apolloClient
            });
        };
        // @ts-ignore
        witRoute.getInitialProps = async (param)=>{
            let { path , apolloClient  } = param;
            let ctx = {};
            await bindCache(apolloClient, CUSTOM_PAGE_QUERY, {
                path
            });
            return {
                ...ctx
            };
        };
        return witRoute;
    };
};
export default ModulesCompose;
