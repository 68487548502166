import Base from "@xem/entity/classes/Base";
import isArray from "@gqlapp/base/utils/isArray";
export class Page extends Base {
    /**
   * instanse
   */ static instanse(data) {
        return new this().save(data || {});
    }
    /**
   * save
   */ save(_value) {
        super.save(_value);
        let self = this;
        let { data , body , status  } = _value || {};
        let { blocks: _blocks , settings  } = data || {};
        let blocks = new Map();
        if (_blocks && _blocks.length) {
            _blocks.map((param)=>{
                let { key , value  } = param;
                if (isArray(value === null || value === void 0 ? void 0 : value.settings)) {
                    value.settings = {};
                }
                blocks.set(key, value);
            });
        }
        if (status === 0) {
            this.status = "inactive";
        } else if (status === -1) {
            this.status = "blocked";
        }
        self.blocks = blocks;
        self.settings = settings;
        self.body = body;
        return self;
    }
    constructor(...args){
        super(...args);
        this.status = "active";
    }
}
export default Page;
