import withProps from "recompose/withProps";
import compose from "recompose/compose";
import mapInput from "@gqlapp/base/utils/mapInput";
import { graphql } from "react-apollo";
import DOMAINS_QUERY from "@xem/domain-common/graphql/domainsQuery.graphql";
/**
 *
 * @param input
 */ export const withDomains = (input)=>compose(withProps((props)=>mapInput(input, props)), withProps((param)=>{
        let { domains: { variables  }  } = param;
        return {
            ...variables
        };
    }), graphql(DOMAINS_QUERY, {
        options: (param)=>{
            let { page , pageSize , orderBy , direction , filter , fetchPolicy  } = param;
            return {
                variables: {
                    page,
                    pageSize,
                    orderBy,
                    direction,
                    filter
                },
                fetchPolicy: fetchPolicy || null
            };
        },
        props (param) {
            let { /**
             *
             */ ownProps: { domains , entitiesReset  } , /**
             *
             */ data: { loading , res , refetch , error , updateQuery , fetchMore , subscribeToMore  }  } = param;
            domains.refetch = refetch;
            domains.fetchMore = fetchMore;
            domains.subscribeToMore = subscribeToMore;
            domains.updateQuery = updateQuery;
            if (!loading && res) {
                domains.update(res, entitiesReset);
            }
            return {
                loading,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
export default withDomains;
